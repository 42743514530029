import { useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { getSelectedRouteByPath } from 'shared/helper/function.helper'
import { setSelectedRoute } from 'redux/route/route.actions.js'

const useSelectedRouteByPath = (path, modifiers = { customRoute: {}, skipGet: false }) => {
  const dispatch = useDispatch()
  const routes = useSelector((state) => state.routes.routes)

  useEffect(() => {
    let page = ''
    let key = ''

    if (!modifiers.skipGet) {
      const routeByPath = getSelectedRouteByPath(routes, path)

      page = routeByPath.subPage ? routeByPath.subPage.title : routeByPath.title
      key = routeByPath.subPage ? routeByPath.subPage.key : routeByPath.key
    }

    dispatch(
      setSelectedRoute({
        page,
        key,
        ...modifiers.customRoute,
      }),
    )
  }, [])
}

export default useSelectedRouteByPath
