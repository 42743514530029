import React from 'react'
import { useSelector } from 'react-redux'
import { PageHeader, Button, Dropdown, Menu, Tag } from 'antd'
import SubMenuBusiness from 'shared/components/subMenuBusiness/subMenuBusiness.componenet'
import { UserOutlined } from '@ant-design/icons'
// import {
//   LOCAL_STORAGE_KEY_PERSISTED_BU,
//   LOCAL_STORAGE_KEY_PERSISTED_MENU_COLLAPSE,
// } from 'shared/helper/constants'
import './header.styles.scss'

import { useMsal } from '@azure/msal-react'

const HeaderComponent = () => {
  const { instance } = useMsal()
  const currentUser = useSelector((state) => state.user.currentUser)
  const currentBusinessUnit = useSelector((state) => state.businessUnit.currentBusinessUnit)
  const pageSelected = useSelector((state) => state.routes.pageSelected)

  const buttonLogin = () => (
    <Menu selectedKeys={currentBusinessUnit}>
      <SubMenuBusiness />
      <Menu.Item
        key='menu-item-logout'
        onClick={() => {
          // window.localStorage.removeItem(LOCAL_STORAGE_KEY_PERSISTED_BU)
          // window.localStorage.removeItem(LOCAL_STORAGE_KEY_PERSISTED_MENU_COLLAPSE)
          instance.logoutRedirect({ postLogoutRedirectUri: '/' })
        }}
      >
        Log out
      </Menu.Item>
    </Menu>
  )

  const titleHeader = () => {
    return (
      <div>
        <span id='header-goBack-button'></span>
        <span>{pageSelected.page}</span>
      </div>
    )
  }

  return (
    <PageHeader
      key='header-page'
      style={{
        background: '#ffffff',
        borderBottom: '1px solid #f1f2f2',
        color: '#131E29',
        // display: 'fex',
        // flexDirection: 'row'
      }}
      className='site-page-header-responsive principal-header'
      // onBack={() => window.history.back()}
      // backIcon={collapsed ? <MenuUnfoldOutlined /> : <MenuFoldOutlined />}
      // onBack={updateToggle}
      title={titleHeader()}
      extra={[
        <Tag key='tag-bu-selected' color='default'>
          Business
        </Tag>,
        <Dropdown overlay={buttonLogin} placement='bottomLeft' key='dropdown-logout'>
          <Button icon={<UserOutlined />} key='btn-new-user' shape='round' type='success'>
            Mary Orquiz
          </Button>
        </Dropdown>,
      ]}
    ></PageHeader>
  )
}

export default HeaderComponent
