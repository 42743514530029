/* eslint-disable prefer-destructuring */
/* eslint-disable max-len */
import businessUnitActionTypes from './businessUnit.types'
import fetchApi from 'redux/api-middleware'
import { generateMiddlewareActionTypes } from 'shared/helper/redux'
import { catalogServices, ApiURL, subCatalogServices } from 'services/catalogs/catalogs.service'

const { URL_CATALOGS } = ApiURL
const { BUSINESS_UNIT_ROUTE } = catalogServices

export const setCurrentBusinessUnit = (businessUnit) => {
  return {
    type: businessUnitActionTypes.SET_CURRENT_BUSINESS_UNIT,
    payload: businessUnit,
  }
}

export const setHeaderBusinessUnits = (businessUnit) => {
  return {
    type: businessUnitActionTypes.SET_HEADER_BUSINESS_UNITS,
    payload: businessUnit,
  }
}

export const setBusinessUnits = (businessUnitList) => {
  return {
    type: businessUnitActionTypes.SET_BUSINESS_UNITS,
    payload: businessUnitList,
  }
}

export const getBusinessUnits = () => async (dispatch) => {
  const { LIST } = subCatalogServices

  const endpoint = `${URL_CATALOGS}/${BUSINESS_UNIT_ROUTE}/${LIST}`

  const types = generateMiddlewareActionTypes(businessUnitActionTypes.GET_BUSINESS_UNIT)

  try {
    const config = {
      types,
      endpoint: endpoint,
      method: 'GET',
    }
    await dispatch(fetchApi(config))
  } catch (err) {
    console.log('err', err)
  }
}

export const postBusinessUnit = (body) => async (dispatch) => {
  let endpoint = `${URL_CATALOGS}/${BUSINESS_UNIT_ROUTE}`
  const types = generateMiddlewareActionTypes(businessUnitActionTypes.POST_BUSINESS_UNIT)

  try {
    const config = {
      types,
      endpoint: endpoint,
      method: 'POST',
      body,
    }
    await dispatch(fetchApi(config))
  } catch (err) {
    console.log('err', err)
    throw Error()
  }
}

export const putBusinessUnit = (body, businessUnitSchema) => async (dispatch) => {
  let endpoint = `${URL_CATALOGS}/${BUSINESS_UNIT_ROUTE}/${businessUnitSchema}`
  const types = generateMiddlewareActionTypes(businessUnitActionTypes.PUT_BUSINESS_UNIT)

  try {
    const config = {
      types,
      endpoint: endpoint,
      method: 'PATCH',
      body,
    }
    await dispatch(fetchApi(config))
    return true
  } catch (err) {
    console.log('err', err)
    throw Error()
  }
}

export const deleteBusinessUnit = (businessSchema) => async (dispatch, getState) => {
  const businessUnits = getState().businessUnit.businessUnits.data
  const headerBusinessUnits = getState().businessUnit.headerBusinessUnits

  const endpoint = `${URL_CATALOGS}/${BUSINESS_UNIT_ROUTE}/${businessSchema}`

  const types = generateMiddlewareActionTypes(businessUnitActionTypes.DELETE_BUSINESS_UNIT)

  try {
    const config = {
      types,
      endpoint: endpoint,
      method: 'DELETE',
    }
    await dispatch(fetchApi(config))
    const filteredBusinessUnit = businessUnits.filter(
      (businessUnit) => businessUnit.businessSchema !== businessSchema,
    )

    dispatch(setBusinessUnits(filteredBusinessUnit))
    headerBusinessUnits.length > 1 && dispatch(setHeaderBusinessUnits(filteredBusinessUnit))
  } catch (err) {
    console.log('err', err)
    throw Error()
  }
}
