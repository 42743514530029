import React from 'react'
import PropTypes from 'prop-types'
import { Tooltip } from 'antd'
import { PartitionOutlined } from '@ant-design/icons'

const GlobalRoleIcon = ({
  text = '',
  tooltipText = 'This role has access to all Business Units',
  disableDefaultStyles = false,
  style = {},
  iconStyle = {},
}) => {
  const defaultStyles = disableDefaultStyles ? {} : { display: 'inline-flex', alignItems: 'center' }

  return (
    <div style={{ ...defaultStyles, ...style }}>
      <Tooltip title={tooltipText}>
        <PartitionOutlined style={{ fontSize: '150%', color: '#8B8B8D', ...iconStyle }} />
      </Tooltip>
      &nbsp; {text}
    </div>
  )
}

GlobalRoleIcon.propTypes = {
  text: PropTypes.string,
  tooltipText: PropTypes.string,
  disableDefaultStyles: PropTypes.bool,
  style: PropTypes.any,
  iconStyle: PropTypes.any,
}

export default GlobalRoleIcon
