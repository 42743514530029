/* eslint-disable prefer-destructuring */
import produce from 'immer'
import EstimateActionTypes from './estimate.types'

const INITIAL_STATE = {
  data: null,
  invoiceDetails: null,
}

const estimateReducer = (state = INITIAL_STATE, action) => {
  return produce(state, (draftState) => {
    switch (action.type) {
      case EstimateActionTypes.GET_PAYMENT:
        draftState.data = action.payload
        break
      case EstimateActionTypes.GET_ESTIMATE_SUCCESS:
        draftState.data = action.payload
        break
      case EstimateActionTypes.GET_ESTIMATE_DETAILS_SUCCESS:
        draftState.invoiceDetails = action.payload
        break
      case EstimateActionTypes.DELETE_ESTIMATE_SUCCESS:
        console.log('action.payload', action.payload)
        draftState.data = draftState.data.filter((estimates) => estimates.order_no !== action.payload.data.order_no)
        break

      default:
        return state
    }
  })
}

export default estimateReducer
