/* eslint-disable no-case-declarations */
/* eslint-disable prefer-destructuring */
import moment from 'moment'
import CaseInformationTypes from './caseInformation.types'

const INITIAL_STATE = {
  caseInfoType: 2,
}

const countSuffix = (name, i) => (i === 0 ? name : `${name}-${i}`)

function buildCaseCaption(caseCaption) {
  // Create independent object
  const objPlaintif = {}
  const objDefense = {}
  // Filter by caption type id
  const countPlaintif = caseCaption.filter((e) => e.captionTypeId === 1)
  const countDefense = caseCaption.filter((e) => e.captionTypeId === 2)
  // Loop to build object property name of type caption with captoname value
  countPlaintif.forEach((e, i) => (objPlaintif[countSuffix('plantiffCaption', i)] = e.captionName))
  countDefense.forEach((e, i) => (objDefense[countSuffix('defenseCaption', i)] = e.captionName))
  // Return merged object
  return { ...objPlaintif, ...objDefense }
}

export const caseInformationReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case CaseInformationTypes.SET_FORM_CASE_INFORMATION:
      return { ...state, ...action.payload }

    case CaseInformationTypes.SET_CASE_INFO_TYPE:
      return { ...state, caseInfoType: action.payload }

    case CaseInformationTypes.GET_CASE_INFORMATION_SUCCESS:
      if (action.payload.caseTypeId === 2) {
        return {
          caseType: action.payload.caseDetailId,
          caseInfoType: action.payload.caseTypeId,
          clientMatterNumber: action.payload.clientMatterNumber,
        }
      }
      return {
        caseType: action.payload.caseDetailId,
        caseInfoType: action.payload.caseTypeId,
        clientMatterNumber: action.payload.clientMatterNumber,
        causeNumber: action.payload.filedCase.causeNumber,
        trialDate: moment(action.payload.filedCase.trialDate),
        dateOfLoss: moment(action.payload.filedCase.dateLoss),
        court: action.payload.court.id,
        courtName: action.payload.court.name,
        ...buildCaseCaption(action.payload.caseCaption),
      }

    case CaseInformationTypes.RESET_CASE_INFORMATION:
      return INITIAL_STATE

    default:
      return state
  }
}

export default caseInformationReducer
