import { generateCrudActionTypes } from 'shared/helper/redux'

const ContactActionTypes = {
  SET_CONTACT_LIST: 'SET_CONTACT_LIST',
  GET_COUNSEL_LIST_START: 'GET_COUNSEL_LIST_START',
  GET_COUNSEL_LIST_SUCCESS: 'GET_COUNSEL_LIST_SUCCESS',
  GET_COUNSEL_LIST_FAILURE: 'GET_COUNSEL_LIST_FAILURE',
  GET_CONTACTS_LIST_START: 'GET_CONTACTS_LIST_START',
  GET_CONTACTS_LIST_SUCCESS: 'GET_CONTACTS_LIST_SUCCESS',
  GET_CONTACTS_LIST_FAILURE: 'GET_CONTACTS_LIST_FAILURE',
  ...generateCrudActionTypes('CONTACT'),
}

export default ContactActionTypes
