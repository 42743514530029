import UserActionTypes from './user.types'
import fetchApi from 'redux/api-middleware'
import { generateMiddlewareActionTypes } from 'shared/helper/redux'
import { catalogServices, ApiURL, subCatalogServices } from 'services/catalogs/catalogs.service'

const { URL_CATALOGS } = ApiURL
const { USER_ROUTE } = catalogServices

export const setCurrentUser = (user) => {
  return {
    type: UserActionTypes.SET_CURRENT_USER,
    payload: user,
  }
}

export const updateToken = (token) => {
  return {
    type: UserActionTypes.UPDATE_TOKEN_USER,
    payload: token,
  }
}

export const setUsersList = (usersList) => {
  return {
    type: UserActionTypes.SET_USERS_LIST,
    payload: usersList,
  }
}

export const addNewUser = (newUser) => {
  return {
    type: UserActionTypes.ADD_NEW_USER,
    payload: newUser,
  }
}

export const deleteUser = (user) => {
  return {
    type: UserActionTypes.DELETE_USER,
    payload: user,
  }
}

export const updateUser = (user) => {
  return {
    type: UserActionTypes.UPDATE_USER,
    payload: user,
  }
}

export const clearUsers = () => {
  return {
    type: UserActionTypes.CLEAR_USERS_INFO,
  }
}

export const getUsersList = () => async (dispatch) => {
  const { LIST } = subCatalogServices
  const endpoint = `${URL_CATALOGS}/${USER_ROUTE}/${LIST}`
  const types = generateMiddlewareActionTypes(UserActionTypes.GET_USERS)
  try {
    const config = {
      types,
      endpoint: endpoint,
      method: 'GET',
    }
    await dispatch(fetchApi(config))
  } catch (error) {
    console.log(error)
    throw Error()
  }
}
