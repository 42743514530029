/* eslint-disable prefer-destructuring */
import FirmsActionTypes from './firms.types'
import produce from 'immer'

const INITIAL_STATE = {
  list: {
    data: [],
  },
}

function buildObjList(payload) {
  return payload.map((firm) => {
    const { addresses, contactInfos } = firm
    const [address] = addresses
    const [contactInfo] = contactInfos
    return {
      id: firm.id,
      name: firm.name,
      firmType: firm.firmType.name,
      email: contactInfo.email,
      phone: contactInfo.phoneNumber,
      city: 'city', // `${address?.city?.name}, ${address?.state?.name}`,
      zipCode: address.zipCode,
      // address: address.address,
      // state: 'state'//address?.state.name,
    }
  })
}

const firmsReducer = (state = INITIAL_STATE, action) => {
  return produce(state, (draftState) => {
    switch (action.type) {
      case FirmsActionTypes.GET_FIRMS_SUCCESS: {
        draftState.list.data = buildObjList(action.payload)
        break
      }
      case FirmsActionTypes.UPDATE_TABLE_FIRMS: {
        draftState.list.data = action.payload
        break
      }
      default:
        return state
    }
  })
}

export default firmsReducer
