import { createStore, applyMiddleware } from 'redux'
import { apiMiddleware } from 'redux-api-middleware'
import thunk from 'redux-thunk'
import { composeWithDevTools } from 'redux-devtools-extension'

import rootReducer from './root-reducer'

const middlewares = [thunk, apiMiddleware]
let compose = null
if (process.env.NODE_ENV === 'development') {
  const composeEnhancers = composeWithDevTools({ trace: true })
  compose = composeEnhancers(applyMiddleware(...middlewares))
} else {
  compose = composeWithDevTools(applyMiddleware(...middlewares))
}
export const store = createStore(rootReducer, compose)

export default { store }
