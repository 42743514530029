/* eslint-disable class-methods-use-this */
export default class Rights {
  constructor(state) {
    this.state = state
  }

  _hasRight(permissions, userPermissions) {
    if (userPermissions && !permissions) {
      return false
    }
    for (let permission of permissions) {
      if (userPermissions.includes(permission)) {
        return true
      }
    }
    return false
  }

  hasRight(right) {
    return this._hasRight(right, this.state)
  }

  static _concatRights(module, subModule, permission) {
    return `${module}_${subModule}_${permission}`
  }
}

Rights.module = {
  DASHBOARD: 'dashboard',
  ORDERS: 'orders',
  ADMIN: 'admin',
  SETTINGS: 'settings',
  BILLING: 'billing',
  ACTIVITIES: 'activities',
  PRODUCTION: 'production',
}

Rights.submodule = {
  DASHBOARD_PAGE: 'dashboard_page',
  ORDERS_PAGE: 'orders_page',
  ADMIN_PAGE: 'admin_Page',
  SETTINGS_PAGE: 'settings_page',
  BUSINESS_PAGE: 'business_unit',
  ROLES: 'roles',
  USERS: 'users',
  PERMISSIONS: 'permissions',
  LOCATIONS: 'locations',
  FIRMS: 'firms',
  CONTACTS: 'contacts',
  ORDERS_INTAKE: 'orders_intake',
  BILLING_QUEUE: 'billing_queue',
  ACCOUNTING_QUEUE: 'accounting_queue',
  PRODUCTION_QUEUE: 'production_queue',
  SHIPMENT_QUEUE: 'shipment_queue',
}

Rights.permission = {
  CREATE_SINGLE: 'create_single',
  CREATE_IN_BATCH: 'create_in_batch',
  SHOW_SINGLE: 'show_single',
  SHOW_LIST: 'show_list',
  UPDATE_SINGLE: 'update_single',
  UPDATE_IN_BATCH: 'update_in_batch',
  ARCHIVE_SINGLE: 'archive_single',
  ARCHIVE_IN_BATCH: 'archive_in_batch',
  HARD_DELETE_SINGLE: 'hard_delete_single',
  HARD_DELETE_IN_BATCH: 'hard_delete_in_batch',
  ACCESS_PAGE: 'access_page',
}
