/* eslint-disable quotes */
import React, { useState, useRef } from 'react'
import Highlighter from 'react-highlight-words'
import { SearchOutlined } from '@ant-design/icons'
import { Button, Input, Space } from 'antd'
import { getObjNestedValue } from 'shared/helper/function.helper'
import GlobalRoleIcon from 'shared/components/globalRole/globalRoleIcon.component'

const GetColumnSearchProps = (dataIndex, conditionalChecks = {}) => {
  const searchInput = useRef()
  const [searchText, setSearchText] = useState('')
  const [searchedColumn, setSearchedColumn] = useState('')

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm()
    setSearchText(selectedKeys[0])
    setSearchedColumn(dataIndex)
  }

  const handleReset = (clearFilters) => {
    clearFilters()
    setSearchText('')
  }

  const filterDropdown = ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
    <div style={{ padding: 8 }}>
      <Input
        ref={searchInput}
        placeholder={`Search ${dataIndex}`}
        value={selectedKeys[0]}
        onChange={({ target }) => setSelectedKeys(target.value ? [target.value] : [])}
        onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
        style={{ marginBottom: 8, display: 'block' }}
      />
      <Space key={`space-firm-${dataIndex}`}>
        <Button
          type='primary'
          onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
          icon={<SearchOutlined />}
          size='small'
          style={{ width: 90 }}
        >
          {'Search'}
        </Button>
        <Button onClick={() => handleReset(clearFilters)} size='small' style={{ width: 90 }}>
          {'Reset'}
        </Button>
        <Button
          type='link'
          size='small'
          onClick={() => {
            confirm({ closeDropdown: false })
            setSearchText(selectedKeys[0])
            setSearchedColumn(dataIndex)
          }}
        >
          {'Filter'}
        </Button>
      </Space>
    </div>
  )

  const filterIcon = (filtered) => (
    <SearchOutlined style={{ color: filtered ? '#991b1d' : undefined }} />
  )

  const onFilter = (value, record) => {
    const nestedValue = getObjNestedValue(record, dataIndex)
    // eslint-disable-next-line prettier/prettier
    return nestedValue ? nestedValue.toString().toLowerCase()
      .includes(value.toLowerCase()) : ''
  }

  const onFilterDropdownVisibleChange = (visible) => {
    return visible && setTimeout(() => searchInput.current.select(), 100)
  }

  const render = (text, record) => {
    let isCurrentColumn = false
    let displayedText = text

    if (typeof searchedColumn === 'object' && searchedColumn !== null) {
      isCurrentColumn = JSON.stringify(searchedColumn) === JSON.stringify(dataIndex)
    } else {
      isCurrentColumn = searchedColumn === dataIndex
    }

    const globalRoleCheck = conditionalChecks.isGlobalRole && record.isGlobalRole

    const highlightedText = () => {
      return (
        <Highlighter
          highlightStyle={{
            backgroundColor: '#ffc069',
            padding: 0,
          }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={displayedText ? displayedText.toString() : ''}
        />
      )
    }

    return isCurrentColumn ? (
      <>{globalRoleCheck ? <GlobalRoleIcon text={highlightedText()} /> : highlightedText()}</>
    ) : (
      <>{globalRoleCheck ? <GlobalRoleIcon text={displayedText} /> : displayedText}</>
    )
  }

  return { filterDropdown, filterIcon, onFilter, onFilterDropdownVisibleChange, render }
}

export default GetColumnSearchProps
