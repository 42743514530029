import { generateCrudActionTypes, generateSpecificCrudActionType } from 'shared/helper/redux'

const DocumentsActionTypes = {
  RESET_DOCUMENTS: 'RESET_DOCUMENTS',
  SET_TABLE_DOCUMENTS: 'SET_TABLE_DOCUMENTS',
  SET_BATCH_LOADING: 'SET_BATCH_LOADING',
  SET_FORM_ERRORS: 'SET_FORM_ERRORS',
  RESET_FORM_ERRORS: 'RESET_FORM_ERRORS',
  SET_UNSAVED_FILES: 'SET_UNSAVED_FILES',
  RESET_UNSAVED_FILES: 'RESET_UNSAVED_FILES',
  DELETE_ROW_TABLE_DOCUMENTS: 'DELETE_ROW_TABLE_DOCUMENTS',
  // Request Types
  UPLOAD_DOCUMENTS_START: 'UPLOAD_DOCUMENTS_START',
  UPLOAD_DOCUMENTS_SUCCESS: 'UPLOAD_DOCUMENTS_SUCCESS',
  UPLOAD_DOCUMENTS_FAILURE: 'UPLOAD_DOCUMENTS_FAILURE',

  ...generateCrudActionTypes('ORDER_DOCUMENTS'),
  ...generateSpecificCrudActionType('COMPLETE_ORDER_DOCUMENTS'),
}

export default DocumentsActionTypes
