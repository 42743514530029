import { useSelector } from 'react-redux'
import Rights from 'rights/rights'

const PermissionsProvider = ({ children, permissions }) => {
  const userPermissions = useSelector((state) => state.right.globalRights)
  const rights = new Rights(userPermissions)
  let resultCheckPermissions = true

  if (userPermissions && permissions) {
    resultCheckPermissions = rights.hasRight(permissions)
  }

  return resultCheckPermissions && children
}

export default PermissionsProvider
