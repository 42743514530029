import RightActionTypes from './right.types'

const INITIAL_STATE = {
  globalRights: [],
}

const rightReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case RightActionTypes.RIGHT_SET_GLOBAL_RIGHTS:
      return {
        ...state,
        globalRights: action.payload,
      }

    default:
      return state
  }
}

export default rightReducer
