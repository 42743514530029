import { generateCrudActionTypes } from 'shared/helper/redux'

const CaseInformationTypes = {
  RESET_CASE_INFORMATION: 'RESET_CASE_INFORMATION',
  SET_FORM_CASE_INFORMATION: 'SET_FORM_CASE_INFORMATION',
  SET_CASE_INFO_TYPE: 'SET_CASE_INFO_TYPE',
  ...generateCrudActionTypes('CASE_INFORMATION'),
}

export default CaseInformationTypes
