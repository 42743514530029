import { generateCrudActionTypes } from 'shared/helper/redux'

const SummaryTypes = {
  RESET_SUMMARY: 'RESET_SUMMARY',
  SET_SUMMARY_ORDERING_PARTY: 'SET_SUMMARY_ORDERING_PARTY',
  SET_SUMMARY_BILLING: 'SET_SUMMARY_BILLING',
  SET_SUMMARY_OTHER_COUNSEL: 'SET_SUMMARY_OTHER_COUNSEL',
  SET_SUMMARY_CASE_INFORMATION: 'SET_SUMMARY_CASE_INFORMATION',
  SET_SUMMARY_SUBJECT_LOCATION: 'SET_SUMMARY_SUBJECT_LOCATION',
  SET_SUMMARY_SCOPE: 'SET_SUMMARY_SCOPE',
  SET_SUMMARY_DOCUMENTS: 'SET_SUMMARY_DOCUMENTS',
  SET_SUMMARY_ADDITIONAL_NOTES: 'SET_SUMMARY_ADDITIONAL_NOTES',
  ...generateCrudActionTypes('SUMMARY'),
}

export default SummaryTypes
