/* eslint-disable prefer-destructuring */
import courtAction from './court.types'
import produce from 'immer'

const INITAL_STATE = {
  courtsList: {
    data: [],
    loading: false,
    error: false,
  },
}

const courtsReducer = (state = INITAL_STATE, action) => {
  return produce(state, (courtState) => {
    switch (action.type) {
      case courtAction.GET_COURTS_START:
        courtState.courtsList.data = []
        courtState.courtsList.loading = false
        courtState.courtsList.error = false
        break

      case courtAction.GET_COURTS_SUCCESS:
        courtState.courtsList.data = action.payload
        courtState.courtsList.loading = false
        courtState.courtsList.error = false
        break

      case courtAction.GET_COURTS_FAILURE:
        courtState.courtsList.loading = false
        courtState.courtsList.error = true
        break

      default:
        break
    }
  })
}

export default courtsReducer
