import { combineReducers } from 'redux'
import userReducer from 'redux/user/user.reducer'
import businessUnitReducer from 'redux/businesUnit/businessUnit.reducer'
import contactSubEntityReducer from 'redux/contactSubEntity/rootContactSubEntity.reducer'
import routeReducer from 'redux/route/route.reducer'
import roleReducer from 'redux/role/role.reducer'
import rightReducer from 'redux/right/right.reducer'
import locationReducer from 'redux/location/location.reducer'
import vendorReducer from 'redux/vendor/vendor.reducer'
import addressReducer from 'redux/address/address.reducer'
import rootOrderIntakeReducer from 'redux/orderIntake/rootOrderIntake.reducer'
import contactReducer from 'redux/contact/contact.reducer'
import advancedSearchReducer from 'redux/advancedSearch/advancedSearch.reducer'
import courtReducer from 'redux/court/court.reducer'
import personTypeReducer from 'redux/personType/personType.reducer'
import firmsReducer from 'redux/firms/firms.reducer'
import permissionReducer from 'redux/permission/permission.reducer'
import legalPersonRolePlayReducer from 'redux/legalPersonRolePlay/legalPersonRolePlay.reducer'
import dashboardReducer from 'redux/dashboard/dashboard.reducer'
import invoiceReducer from 'redux/invoice/invoice.reducer'
// import paymentReducer from 'redux/payment/rootPayment.reducer'
import rootQueueReducer from 'redux/queue/rootQueue.reducer'
import paymentMethodReducer from 'redux/paymentMethod/paymentMethod.reducer'
import customerReducer from 'redux/customer/customer.reducer'
import estimateReducer from 'redux/estimate/estimate.reducer'
import { loadingReducer, errorReducer } from 'redux/api/apiReducers'

const rootReducer = combineReducers({
  dashboard: dashboardReducer,
  user: userReducer,
  businessUnit: businessUnitReducer,
  routes: routeReducer,
  role: roleReducer,
  right: rightReducer,
  location: locationReducer,
  vendor: vendorReducer,
  address: addressReducer,
  orderIntake: rootOrderIntakeReducer,
  contactSubEntities: contactSubEntityReducer,
  contacts: contactReducer,
  advancedSearch: advancedSearchReducer,
  court: courtReducer,
  personType: personTypeReducer,
  firms: firmsReducer,
  legalPersonRolePlay: legalPersonRolePlayReducer,
  permission: permissionReducer,
  invoice: invoiceReducer,
  // payment: paymentReducer,
  queue: rootQueueReducer,
  paymentMethod: paymentMethodReducer,
  customer: customerReducer,
  estimate: estimateReducer,
  loadingReducer,
  errorReducer,
})

export default rootReducer
