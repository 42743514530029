import { generateCrudActionTypes, generateSpecificCrudActionType } from 'shared/helper/redux'

const RoleActionTypes = {
  SET_ROLES_LIST: 'SET_ROLES_LIST',
  ADD_NEW_ROLE: 'ADD_NEW_ROLE',
  UPDATE_ROLE: 'UPDATE_ROLE',
  GET_SINGLE_ROLE: 'GET_SINGLE_ROLE',
  ROLES_RESET: 'ROLES_RESET',
  DELETE_ROLE: 'DELETE_ROLE',
  ...generateCrudActionTypes('ROLES'),
  ...generateSpecificCrudActionType('GET_ROLE_TYPES'),
  ...generateSpecificCrudActionType('GET_ROLE_GROUPS'),
}

export default RoleActionTypes
