/* eslint-disable prefer-destructuring */
import businessUnitActionTypes from './businessUnit.types'
import produce from 'immer'

const INITAL_STATE = {
  currentBusinessUnit: null,
  headerBusinessUnits: [],
  businessUnits: {
    data: [],
  },
}

const businessUnitReducer = (state = INITAL_STATE, action) => {
  return produce(state, (draftState) => {
    switch (action.type) {
      case businessUnitActionTypes.SET_CURRENT_BUSINESS_UNIT:
        draftState.currentBusinessUnit = action.payload
        break

      case businessUnitActionTypes.SET_HEADER_BUSINESS_UNITS:
        draftState.headerBusinessUnits = action.payload
        break

      // Middleware
      case businessUnitActionTypes.SET_BUSINESS_UNITS:
        draftState.businessUnits.data = action.payload
        break

      case businessUnitActionTypes.GET_BUSINESS_UNIT_SUCCESS: {
        const objBusinessUnit = action.payload.map((businessUnit) => {
          return {
            businessSchema: businessUnit.schema,
            businessName: businessUnit.name,
          }
        })
        draftState.businessUnits.data = objBusinessUnit
        break
      }

      case businessUnitActionTypes.GET_BUSINESS_UNIT_FAILURE:
        // draftState.businessUnits.data = []
        break

      case businessUnitActionTypes.DELETE_BUSINESS_UNIT_SUCCESS: {
        // console.log('action', action)
        break
      }

      default:
        break
    }
  })
}

export default businessUnitReducer
