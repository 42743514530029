/* eslint-disable prefer-destructuring */
import DocumentLibraryTypes from './documentLibrary.types'
import produce from 'immer'

const INITAL_STATE = {
  templatesDocumentLibrary: [],
}

const documentLibraryReducer = (state = INITAL_STATE, action) => {
  return produce(state, (draftState) => {
    switch (action.type) {
      // Middleware
      case DocumentLibraryTypes.SET_TEMPLATES_DOCUMENT_LIBRARY:
        draftState.templatesDocumentLibrary = action.payload
        break

      default:
        break
    }
  })
}

export default documentLibraryReducer
