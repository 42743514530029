/* eslint-disable prefer-destructuring */
import BillingTypes from './billing.types'
import produce from 'immer'

const INITIAL_STATE = {
  data: null,
  id: null,
  firmsList: [],
  attorneysList: [],
}

export const billingReducer = (state = INITIAL_STATE, action) => {
  return produce(state, (draftState) => {
    switch (action.type) {
      case BillingTypes.SET_FORM_BILLING:
        draftState.data = { ...state.data, ...action.payload }
        break
      case BillingTypes.RESET_BILLING:
        return INITIAL_STATE

      case BillingTypes.GET_BILLING_ORDER_SUCCESS:
        {
          // let billingFirm
          // let billingAttorney

          if (action.payload?.billingAttorney && action.payload?.billingFirm) {
            // Step 2 was saved
            draftState.data = {
              ...state.data,
              ...action.payload,
            }

            // billingFirm = action.payload?.firm
            // billingAttorney = action.payload?.legalPerson

            // endif
          } else {
            // Step 2 was not saved
            // console.log('state.data', state.data)
            // billingFirm = state.data?.firm
            // billingAttorney = state.data?.legalPerson
          }

          // Firm Validation

          // const [selectedFirm] = draftState.firmsList.filter((firm) => {
          //   return firm.id === billingFirm.id
          // })

          // if (!selectedFirm) {
          //   draftState.firmsList.push(billingFirm)
          // }

          // // Attorney Validation

          // const [selectedAttorney] = draftState.attorneysList.filter((attorney) => {
          //   return attorney.id === billingAttorney.id
          // })

          // if (!selectedAttorney) {
          //   // eslint-disable-next-line max-len
          //   const name = `${billingAttorney?.person?.firstName} ${billingAttorney?.person?.lastName}`

          //   draftState.attorneysList.push({ name, ...billingAttorney })
          // }
          //
        }
        break

      case BillingTypes.SET_BILLING_ATTORNEYS_LIST:
        draftState.attorneysList = action.payload
        break

      case BillingTypes.GET_BILLING_ATTORNEYS_LIST_SUCCESS: {
        const attorneys = []

        action.payload &&
          action.payload.forEach((attorney) => {
            if (attorney.legalPerson?.[0]) {
              const legalPersonData = {
                ...attorney,
                name: `${attorney.firstName} ${attorney.lastName}`,
                id: attorney.legalPerson[0].id,
              }

              attorneys.push(legalPersonData)
            }
          })
        draftState.attorneysList = attorneys

        // attorneysList = []

        // const [selectedAttorney] = attorneysList.filter(
        //   (attorney) => attorney.id === draftState.data?.billingAttorney,
        // )

        // draftState.attorneysList = selectedAttorney
        //   ? attorneysList
        //   : [...draftState.attorneysList, ...attorneysList]

        break
      }

      case BillingTypes.SET_BILLING_FIRMS_LIST:
        draftState.firmsList = action.payload
        break

      case BillingTypes.GET_BILLING_FIRMS_LIST_SUCCESS: {
        let mappedFirms = action.payload.map((firm) => {
          // const { address } = firm.addresses[0]
          const address = firm.addresses[0]
          const { phoneNumber } = firm.contactInfos[0]
          return {
            id: firm.id,
            name: firm.name,
            address,
            phone: phoneNumber,
            type: firm.firmType.id,
          }
        })

        // mappedFirms = []

        // const [selectedFirm] = mappedFirms.filter(
        //   (firm) => firm.id === draftState.data?.billingFirm,
        // )

        // draftState.firmsList = selectedFirm
        //   ? mappedFirms
        //   : [...draftState.firmsList, ...mappedFirms]

        draftState.firmsList = mappedFirms

        break
      }

      default:
        return state
    }
  })
}

export default billingReducer
