/* eslint-disable prefer-destructuring */
import AddressActionTypes from './address.types'
import produce from 'immer'

const INITIAL_STATE = {
  citiesList: [],
  states: {
    data: [],
    loading: false,
    error: null,
  },
  counties: {
    data: [],
    loading: false,
    error: null,
  },
}

const addressReducer = (state = INITIAL_STATE, action) => {
  return produce(state, (draftState) => {
    switch (action.type) {
      case AddressActionTypes.SET_CITIES_LIST:
        draftState.citiesList = action.payload
        break
      case AddressActionTypes.GET_STATES_START:
        draftState.states.loading = true
        draftState.states.error = null
        break
      case AddressActionTypes.GET_STATES_SUCCESS:
        draftState.states.data = action.payload
        draftState.states.loading = false
        break
      case AddressActionTypes.GET_STATES_FAILURE:
        draftState.states.loading = false
        draftState.states.error = action.payload.response
        break

      case AddressActionTypes.GET_COUNTIES_START:
        draftState.counties.loading = true
        draftState.counties.error = null
        break
      case AddressActionTypes.GET_COUNTIES_SUCCESS:
        draftState.counties.data = action.payload
        draftState.counties.loading = false
        break
      case AddressActionTypes.GET_COUNTIES_FAILURE:
        draftState.counties.loading = false
        draftState.counties.error = action.payload.response
        break
      default:
        return state
    }
  })
}

export default addressReducer
