/* eslint-disable no-use-before-define */
import React from 'react'
import PropTypes from 'prop-types'
import { useHistory } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'
import 'antd/dist/antd.css'
import { Menu } from 'antd'
import { setSelectedRoute } from 'redux/route/route.actions.js'
import PermissionsProvider from 'shared/components/permissionsProvider/permissionsProvider.component'
import { MenuUnfoldOutlined, MenuFoldOutlined } from '@ant-design/icons'
import { getSelectedRouteByPath } from 'shared/helper/function.helper'
import useSelectedRouteByPath from 'shared/hooks/useSelectedRouteByPath.hook'

const { SubMenu } = Menu

const externalMenuTitles = {
  dataEntry: 'Order Records',
  newOrder: 'Add Order',
}

const ROSMenu = ({ updateToggle, collapsed }) => {
  const history = useHistory()
  const dispatch = useDispatch()

  const routes = useSelector((state) => state.routes.routes)
  const pageSelected = useSelector((state) => state.routes.pageSelected)
  const routeByPath = getSelectedRouteByPath(routes, window.location.pathname)

  const userRoleType = useSelector((state) => state?.user?.currentUser?.roleType ?? '')
  const isExternal = useSelector((state) => state?.user?.currentUser?.roleType) === 'external'

  useSelectedRouteByPath(window.location.pathname)

  const handleClickMenu = (linkUrl, title, key) => {
    dispatch(
      setSelectedRoute({
        page: title,
        key,
      }),
    )
    history.push(`${linkUrl}`)
  }

  const routeMenu = (routes) => {
    return routes.map((route) => {
      if (!isExternal && route?.linkUrl?.includes('quick')) {
        return null
      }

      return menuItem(route)
    })
  }

  const getMenuTitle = (route) => {
    if (userRoleType === 'external') {
      return externalMenuTitles[route.key] || route.title
    }

    return route.title
  }

  const subMenuItem = (subPages, route, permissions, key) => {
    return (
      <PermissionsProvider key={`permissions-prov-${key}`} permissions={permissions}>
        <SubMenu
          eventKey={`permissions-submenu-${key}`}
          key={route.key}
          icon={route.icon}
          title={getMenuTitle(route)}
        >
          <Menu.ItemGroup key={`item-group-${route.key}`} title=''>
            {routeMenu(subPages)}
          </Menu.ItemGroup>
        </SubMenu>
      </PermissionsProvider>
    )
  }

  const menuItem = (route) => {
    const { title, key, linkUrl, icon, sider, subPages, parent, permissions } = route
    if (sider) {
      if (subPages.length > 0 && parent < 2) {
        return subMenuItem(subPages, route, permissions, key)
      }
      return (
        <PermissionsProvider key={`permissions-${key}`} permissions={permissions}>
          <Menu.Item
            key={key}
            eventKey={key}
            icon={icon}
            onClick={() => handleClickMenu(linkUrl, title, key)}
          >
            <span>{getMenuTitle(route)}</span>
          </Menu.Item>
        </PermissionsProvider>
      )
    }
  }

  return (
    <React.Fragment>
      <Menu
        style={{
          // height: '100%',
          borderRight: 0,
        }}
        defaultOpenKeys={[`permissions-submenu-${routeByPath.key}`]}
        selectedKeys={[pageSelected.key]}
        mode='inline'
        className='sider-menu'
      >
        {routeMenu(routes)}
      </Menu>
      <button className='btn-toggle-sider' onClick={updateToggle}>
        {collapsed ? <MenuUnfoldOutlined /> : <MenuFoldOutlined />}
      </button>
    </React.Fragment>
  )
}

ROSMenu.propTypes = {
  updateToggle: PropTypes.func,
  collapsed: PropTypes.bool,
}

export default ROSMenu
