import { generateCrudActionTypes } from 'shared/helper/redux'

const ScopeTypes = {
  RESET_SCOPE: 'RESET_SCOPE',
  SET_SUBJECTS_LIST: 'SET_SUBJECTS_LIST',
  UPDATE_SUBJECT: 'UPDATE_SUBJECT',
  SET_SELECTED_SUBJECT: 'SET_SELECTED_SUBJECT',
  SET_SELECTED_LOCATIONS: 'SET_SELECTED_LOCATIONS',
  SET_SELECTED_RECORDS: 'SET_SELECTED_RECORDS',
  ...generateCrudActionTypes('SCOPE_RECORD_TYPES'),
  ...generateCrudActionTypes('SCOPE'),
}

export default ScopeTypes
