/* eslint-disable prefer-destructuring */
import OrderingPartyTypes from './orderingParty.types'
import produce from 'immer'

const INITIAL_STATE = {
  data: null,
  loading: false,
  error: null,
  id: null,
  columnsName: [],
  people: [],
  firmsList: undefined,
  attorneyTypeList: undefined,
  attorneysList: [],
}

export const orderingPartyReducer = (state = INITIAL_STATE, action) => {
  return produce(state, (draftState) => {
    switch (action.type) {
      case OrderingPartyTypes.SET_FORM_ORDERING_PARTY:
        draftState.data = { ...state.data, ...action.payload }
        break

      case OrderingPartyTypes.RESET_ORDERING_PARTY:
        return INITIAL_STATE

      case OrderingPartyTypes.UPDATE_ORDERING_ID:
        draftState.id = action.payload
        break

      case OrderingPartyTypes.GET_ORDERING_SUCCESS:
        draftState.data = action.payload
        break

      case OrderingPartyTypes.GET_ORDERING_PARTY_COLUMNS_NAME_SUCCESS: {
        const columnsNameList = action.payload
        draftState.columnsName = columnsNameList.map((column) => ({
          ...column,
          key: column.name.replace(/ /g, ''),
        }))

        break
      }

      case OrderingPartyTypes.GET_ORDERING_PARTY_ATTORNEY_CONTACTS_SUCCESS: {
        const { contactInfo } = action.payload
        const formatedPeople = contactInfo.map((contact) => ({
          ...contact,
          name: `${contact.person.firstName} ${contact.person.lastName}`,
        }))

        draftState.people = formatedPeople

        break
      }

      case OrderingPartyTypes.GET_ORDERING_PARTY_ATTORNEYS_LIST_SUCCESS: {
        const attorneys = action.payload
        const mappedAttorneys = attorneys.map((attorney) => ({
          ...attorney,
          name: `${attorney.firstName} ${attorney.lastName}`,
          id: attorney.legalPerson[0]?.id,
        }))

        draftState.attorneysList = mappedAttorneys

        break
      }

      case OrderingPartyTypes.GET_ORDERING_PARTY_FIRMS_LIST_SUCCESS: {
        const responseFirms = action.payload
        const mappedFirms = responseFirms.map((firm) => {
          // needs to be removed
          // const { address } = firm.addresses[0]
          const address = firm.addresses ? firm.addresses[0] : null
          const { phoneNumber } = firm.contactInfos[0]
          return {
            id: firm.id,
            name: firm.name,
            address: address ? address : null,
            phone: phoneNumber,
          }
        })

        draftState.firmsList = mappedFirms

        break
      }

      case OrderingPartyTypes.GET_ORDERING_PARTY_ATTORNEY_TYPE_LIST_SUCCESS: {
        const attorneyTypes = action.payload
        draftState.attorneyTypeList = attorneyTypes

        break
      }

      default:
        return state
    }
  })
}

export default orderingPartyReducer
