import SubjectLocationsTypes from 'redux/orderIntake/subjectLocations/subjectLocations.types'

const INITIAL_STATE = {
  data: [],
  dataTableMoreLocations: [],
  dataTableRecommendedLocations: [],
  modalSelectedRecommended: [],
  modalSelectedMoreLocations: [],
}

function formatedLocations(data) {
  return data.map((e) => {
    return {
      id: e.locationId,
      locationName: e.location.name,
      contactName: 'Jane Zane', // como se si es doctorname
      department: 'Medical Records', // como se si department
      serviceType: e.details[0].serviceType.name,
      recordsRequestBy: e.details[0].requestType.name,
      aditionalService: 'no', // como se si es aditionalservice
    }
  })
}

function transformDataSubjectLocation(data) {
  const formated = data.subjects.map((e) => {
    return {
      id: e.subjectId,
      steps: {
        one: {
          guardianExecutor: e.guardian,
          firstName: e.person.firstName,
          middleName: e.person.middleName,
          lastName: e.person.lastName,
          dateOfBirth: e.person.dateBirth,
          dateOfDeath: e.person.dateDeath,
          email: e.person.contactInfos[0].email,
          mobileNumber: e.person.contactInfos[0].mobileNumber,
          personId: e.personId,
          ssn: e.person.ssn,
          firstAddress: e.person.addresses[0].address,
          secondAddress: e.person.addresses[0].address1,
          zipCode: e.person.addresses[0].zipCode,
          subjectType: e.subjectType.name,
          stateId: e.person.addresses[0].stateId,
          cityId: e.person.addresses[0].cityId,
        },
        two: {
          recommended: [], // como se si es recommended o selected
          selected: formatedLocations(e.subjectLocations),
        },
      },
    }
  })
  return formated
}

export const subjectLocationsReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case SubjectLocationsTypes.SET_FORM_SUBJECT_LOCATION:
      return action.payload
    case SubjectLocationsTypes.RESET_SUBJECT_LOCATION:
      return INITIAL_STATE
    case SubjectLocationsTypes.SAVE_MODAL_DATA_SUBJECT_LOCATION:
      return {
        ...state,
        data: [...state.data, action.payload],
      }
    case SubjectLocationsTypes.UPDATE_MODAL_DATA_SUBJECT_LOCATION:
      return {
        ...state,
        data: action.payload,
      }
    case SubjectLocationsTypes.UPDATE_MORE_LOCATIONS_SELECTED:
      return {
        ...state,
        modalSelectedMoreLocations: action.payload,
      }
    case SubjectLocationsTypes.UPDATE_DATA_TABLE_MORE_LOCATIONS:
      return {
        ...state,
        dataTableMoreLocations: action.payload,
      }
    case SubjectLocationsTypes.UPDATE_RECOMMENDED_LOCATIONS_SELECTED:
      return {
        ...state,
        modalSelectedRecommended: action.payload,
      }
    case SubjectLocationsTypes.UPDATE_DATA_TABLE_RECOMMENDED_LOCATIONS:
      return {
        ...state,
        dataTableRecommendedLocations: action.payload,
      }
    case SubjectLocationsTypes.DELETE_SUBJECT_LOCATION:
      return {
        ...state,
        data: action.payload,
      }
    case SubjectLocationsTypes.DELETE_LOCATION:
      return {
        ...state,
        data: action.payload,
      }
    case SubjectLocationsTypes.UPDATE_TABLE_LOCATION:
      return {
        ...state,
        data: action.payload,
      }
    case SubjectLocationsTypes.CLEAR_DATA_TABLE_SUBJECT_LOCATIONS:
      return {
        ...state,
        dataTableMoreLocations: [],
        dataTableRecommendedLocations: [],
        modalSelectedRecommended: [],
        modalSelectedMoreLocations: [],
      }
    case SubjectLocationsTypes.GET_SUBJECT_LOCATION_SUCCESS:
      return {
        ...state,
        data: transformDataSubjectLocation(action.payload),
      }
    default:
      return state
  }
}

export default subjectLocationsReducer
