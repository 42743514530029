import { generateCrudActionTypes } from 'shared/helper/redux'

const UserActionTypes = {
  SET_CURRENT_USER: 'SET_CURRENT_USER',
  SET_USERS_LIST: 'SET_USERS_LIST',
  ADD_NEW_USER: 'ADD_NEW_USER',
  DELETE_USER: 'DELETE_USER',
  UPDATE_USER: 'UPDATE_USER',
  UPDATE_TOKEN_USER: 'UPDATE_TOKEN_USER',
  CLEAR_USERS_INFO: 'CLEAR_USERS_INFO',
  ...generateCrudActionTypes('USERS'),
}

export default UserActionTypes
