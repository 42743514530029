import { generateCrudActionTypes, generateSpecificCrudActionType } from 'shared/helper/redux'

const OrderingPartyTypes = {
  RESET_ORDERING_PARTY: 'RESET_ORDERING_PARTY',
  SET_FORM_ORDERING_PARTY: 'SET_FORM_ORDERING_PARTY',
  UPDATE_ORDERING_ID: 'UPDATE_ORDERING_ID',

  SET_ORDERING_PARTY_ATTORNEYS_LIST: 'SET_ORDERING_PARTY_ATTORNEYS_LIST',
  //
  ...generateCrudActionTypes('ORDERING'),
  //
  ...generateSpecificCrudActionType('GET_ORDERING_PARTY_COLUMNS_NAME'),
  ...generateSpecificCrudActionType('GET_ORDERING_PARTY_ATTORNEY_CONTACTS'),
  //
  ...generateSpecificCrudActionType('GET_ORDERING_PARTY_FIRMS_LIST'),
  ...generateSpecificCrudActionType('GET_ORDERING_PARTY_ATTORNEYS_LIST'),
  ...generateSpecificCrudActionType('GET_ORDERING_PARTY_ATTORNEY_TYPE_LIST'),
}

export default OrderingPartyTypes
