import { generateCrudActionTypes } from 'shared/helper/redux'

const businessUnitActionTypes = {
  SET_CURRENT_BUSINESS_UNIT: 'SET_CURRENT_BUSINESS_UNIT',
  SET_HEADER_BUSINESS_UNITS: 'SET_HEADER_BUSINESS_UNITS',
  SET_BUSINESS_UNITS: 'SET_BUSINESS_UNITS',
  ...generateCrudActionTypes('BUSINESS_UNIT'),
}

export default businessUnitActionTypes
