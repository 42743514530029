import { generateCrudActionTypes, generateSpecificCrudActionType } from 'shared/helper/redux'

const BillingTypes = {
  RESET_BILLING: 'RESET_BILLING',
  SET_FORM_BILLING: 'SET_FORM_BILLING',
  //
  SET_BILLING_FIRMS_LIST: 'SET_BILLING_FIRMS',
  SET_BILLING_ATTORNEYS_LIST: 'SET_BILLING_ATTORNEYS_LIST',
  //
  //
  ...generateSpecificCrudActionType('GET_BILLING_ORDER'),
  ...generateSpecificCrudActionType('PUT_BILLING_ORDER'),
  //
  ...generateCrudActionTypes('BILLING_FIRMS_LIST'),
  ...generateCrudActionTypes('BILLING_ATTORNEYS_LIST'),
}

export default BillingTypes
