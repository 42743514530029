import React from 'react'
import {
  AppstoreOutlined,
  SettingOutlined,
  CopyOutlined,
  UserAddOutlined,
} from '@ant-design/icons'
import RouteActionTypes from './route.types'
// import Rights from 'rights/rights'
// import * as PERMISSIONS from 'rights/groupPermissions'

export const INITIAL_STATE = {
  routes: [
    {
      title: 'Dashboard',
      key: 'dashboard',
      icon: <AppstoreOutlined />,
      id: 1,
      linkUrl: '/dashboard',
      exact: true,
      sider: true,
      permissions: null,
      subPages: [],
      parent: 1,
    },
    {
      title: 'Estimate',
      key: 'estimate',
      icon: <CopyOutlined />,
      id: 2,
      linkUrl: '/estimate',
      exact: true,
      sider: true,
      permissions: null,
      parent: 1,
      subPages: [
        {
          title: 'Estimates',
          key: 'estimates',
          icon: null,
          id: 1,
          linkUrl: '/estimate/',
          exact: true,
          sider: true,
          parent: 2,
          permissions: null,
          subPages: [],
        },
        {
          title: 'New Estimate',
          key: 'newEstimate',
          icon: null,
          id: 2,
          linkUrl: '/estimate/new',
          exact: true,
          sider: true,
          parent: 2,
          permissions: null,
          subPages: [],
        },
        {
          title: 'Edit Estimate',
          key: 'editEstimate',
          icon: null,
          id: 3,
          linkUrl: '/estimate/edit/:invoiceId',
          exact: true,
          sider: false,
          parent: 2,
          permissions: null,
          subPages: [],
        },
      ],
    },
    {
      title: 'Customer',
      key: 'Customer',
      icon: <UserAddOutlined />,
      id: 2,
      linkUrl: '/customer',
      exact: true,
      sider: true,
      permissions: null,
      parent: 1,
      subPages: [
        {
          title: 'Customers',
          key: 'customer',
          icon: null,
          id: 1,
          linkUrl: '/customer/',
          exact: true,
          sider: true,
          parent: 2,
          permissions: null,
          subPages: [],
        },
        {
          title: 'New Customer',
          key: 'newCustomer',
          icon: null,
          id: 2,
          linkUrl: '/customer/new',
          exact: true,
          sider: true,
          parent: 2,
          permissions: null,
          subPages: [],
        },
        {
          title: 'Edit Customer',
          key: 'editCustomer',
          icon: null,
          id: 3,
          linkUrl: '/customer/new/:customerId',
          exact: true,
          sider: false,
          parent: 2,
          permissions: null,
          subPages: [],
        },
      ],
    },
    {
      title: 'Settings',
      key: 'settings',
      icon: <SettingOutlined />,
      id: 7,
      linkUrl: '/settings',
      exact: true,
      sider: true,
      subPages: [],
      permissions: null,
      parent: 1,
    },
  ],
  pageSelected: {
    page: 'Dashboard',
    key: 'dashboard',
  },
}

const routeReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case RouteActionTypes.SET_SELECTED_ROUTE:
      return {
        ...state,
        pageSelected: action.payload,
      }
    default:
      return state
  }
}

export default routeReducer
