/* eslint-disable prefer-destructuring */
import InvoiceTypes from './invoice.types'
import produce from 'immer'
import { getFullName } from 'shared/helper/function.helper'

const INITIAL_STATE = {
  data: {
    details: null,
    inoices: null,
  },
}

function buildObjList(payload) {
  const dataInvoice = {
    orderingAttorney: [],
    otherCounsels: [],
  }
  payload.invoices.forEach((invoice) => {
    const orderingAttorney = getFullName(
      payload?.orderingAttorney?.person?.firstName,
      payload?.orderingAttorney?.person?.lastName,
    )
    const billingAttorney = getFullName(
      payload?.billingAttorney?.person?.firstName,
      payload?.billingAttorney?.person?.lastName,
    )
    if (invoice?.attorney) {
      dataInvoice.orderingAttorney.push({
        id: invoice?.personId,
        name: getFullName(
          invoice?.attorney?.legalPerson?.person?.firstName,
          invoice?.attorney?.legalPerson?.person?.lastName,
        ),
        orderNumber: payload.orderId,
        locationNumber: payload?.locationNumber,
        subject: payload?.subjectName,
        location: payload?.locationName,
        requestType: payload?.requestType,
        orderingAttorney,
        contact: orderingAttorney,
        billingAttorney,
        claimNumber: payload?.claim,
        clientFileNumber: payload?.clientFile,
        court: payload?.court ? payload?.court : 'NA',
        invoices: invoice.fees,
        expenses: invoice.expenses,
      })
    } else if (invoice?.otherCounsel) {
      dataInvoice.otherCounsels.push({
        id: invoice?.otherCounsel?.personId,
        name: getFullName(
          invoice?.otherCounsel?.person?.firstName,
          invoice?.otherCounsel?.person?.lastName,
        ),
        orderNumber: payload?.orderId,
        locationNumber: payload?.locationNumber,
        subject: payload?.subjectName,
        location: payload?.locationName,
        requestType: payload?.requestType,
        orderingAttorney,
        contact: orderingAttorney,
        billingAttorney: getFullName(
          invoice?.otherCounsel?.person?.firstName,
          invoice?.otherCounsel?.person?.lastName,
        ),
        claimNumber: payload?.claim,
        clientFileNumber: payload?.clientFile,
        court: payload?.court ? payload?.court : 'NA',
        invoices: invoice.fees,
        expenses: invoice.expenses,
      })
    }
  })
  return dataInvoice
}

const builObjInvoice = (objInvoice) => {
  let invoices = []
  objInvoice &&
    objInvoice.invoices.forEach((invoice) => {
      let invoiceCopy = { ...invoice }
      if (invoice.description === 'Prepaid Expenses') {
        if (objInvoice.expenses) {
          let children = objInvoice.expenses.map((expenses) => {
            return {
              id: expenses.invoiceExpenseId,
              ...expenses,
              isChild: true,
            }
          })
          invoiceCopy.children = children
          invoiceCopy.isExpandable = true
        }
      }
      invoiceCopy.id = invoice.billingRateId
      invoices.push(invoiceCopy)
    })

  return invoices
}

const invoiceReducer = (state = INITIAL_STATE, action) => {
  return produce(state, (draftState) => {
    switch (action.type) {
      case InvoiceTypes.GET_INVOICE_DETAILS_SUCCESS: {
        draftState.data.details = buildObjList(action.payload)
        break
      }
      case InvoiceTypes.GET_INVOICES_BY_ID: {
        draftState.data.invoices = builObjInvoice(action.payload)
        break
      }
      default:
        return state
    }
  })
}

export default invoiceReducer
