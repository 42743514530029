/* eslint-disable prefer-destructuring */
import ScopeTypes from './scope.types'
import produce from 'immer'

const INITIAL_STATE = {
  data: {
    subjectsList: [],
    selectedSubject: { locationScopes: [] },
    selectedLocations: {
      id: 0,
      list: [],
    },
    selectedRecords: [
      /* {
          name:'medical',
          dateOfBirth:'dd/mm/yyyy - dd/mm/yyyy',
           dateOfLost:'dd/mm/yyyy - dd/mm/yyyy',
           endDate:'dd/mm/yyyy',
           note:''
        }*/
    ],
  },
  recordTypes: [
    {
      id: 1,
      name: 'Record Type',
      recordTypes: [],
    },
    {
      id: 2,
      name: 'Other Record Type',
      recordTypes: [],
    },
  ],
}

export const scopeReducer = (state = INITIAL_STATE, action) => {
  return produce(state, (draftState) => {
    switch (action.type) {
      case ScopeTypes.SET_SUBJECTS_LIST:
        draftState.data.subjectsList = action.payload
        break

      case ScopeTypes.SET_SELECTED_SUBJECT:
        draftState.data.selectedSubject = action.payload
        if (action.payload.id !== state.data.selectedLocations.id) {
          draftState.data.selectedLocations = { id: action.payload.id, list: [] }
          draftState.data.selectedRecords = []
        }
        break

      case ScopeTypes.SET_SELECTED_LOCATIONS:
        draftState.data.selectedLocations = action.payload
        break

      case ScopeTypes.SET_SELECTED_RECORDS:
        draftState.data.selectedRecords = action.payload
        break

      case ScopeTypes.GET_SCOPE_RECORD_TYPES_SUCCESS:
        draftState.recordTypes = action.payload
        break

      case ScopeTypes.GET_SCOPE_SUCCESS:
        draftState.data.subjectsList = action.payload.map((subject) => ({
          ...subject,
          completed: !subject.locationScopes.find((location) => location.scopes.length === 0),
        }))
        break

      case ScopeTypes.RESET_SCOPE:
        return INITIAL_STATE

      default:
        return state
    }
  })
}

export default scopeReducer
