/* eslint-disable import/prefer-default-export */

import { REDUX_ID_SEPARATOR } from 'shared/helper/constants'
import { getReduxIdSuffix } from 'shared/helper/redux'

const payloadResponseFlattener = (payload, key) => {
  if (payload && typeof payload === 'object' && payload.response) {
    const flattenedObj = { ...payload, ...payload[key] }
    delete flattenedObj[key]

    return flattenedObj
  }

  return payload
}

export const loadingReducer = (state = {}, action) => {
  const { type: typeWithId } = action

  const [type, id] = typeWithId.split(REDUX_ID_SEPARATOR)

  const matches = /(.*)_(START|SUCCESS|FAILURE)/.exec(type)

  // not a *_REQUEST / *_SUCCESS /  *_FAILURE actions, so we ignore them
  if (!matches) {
    return state
  }

  const [, requestName, requestState] = matches
  return {
    ...state,
    [`${requestName}${getReduxIdSuffix(id)}`]: requestState === 'START',
  }
}

export const errorReducer = (state = {}, action) => {
  const { type: typeWithId, payload } = action
  const [type, id] = typeWithId.split(REDUX_ID_SEPARATOR)

  const matches = /(.*)_(START|FAILURE|CLEAN)/.exec(type)

  // not a *_REQUEST / *_FAILURE / *_CLEAN  actions, so we ignore them

  if (!matches) {
    return state
  }
  const [, requestName, requestState] = matches

  return {
    ...state,
    [`${requestName}${getReduxIdSuffix(id)}`]:
      requestState === 'FAILURE' ? payloadResponseFlattener(payload, 'response') : null,
  }
}
