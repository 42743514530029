import { generateSpecificCrudActionType } from 'shared/helper/redux'

const ConfigTypes = {
  RESET_CONFIG: 'RESET_CONFIG',
  SET_NEXT_AVAILABLE: 'SET_NEXT_AVAILABLE',
  SET_SELECTED_VIEW: 'SET_SELECTED_VIEW',
  SET_ACTIVE_STEP: 'SET_ACTIVE_STEP',
  SET_UPDATE_MODE: 'SET_UPDATE_MODE',
  // GET_ORDER_START: 'GET_ORDER_START',
  // GET_ORDER_SUCCESS: 'GET_ORDER_SUCCESS',
  // GET_ORDER_FAILURE: 'GET_ORDER_FAILURE',
  SET_ID_ORDER: 'SET_ID_ORDER',
  //
  ...generateSpecificCrudActionType('GET_EXTERNAL_CUSTOMER_ORDER_DETAILS'),
  ...generateSpecificCrudActionType('GET_ORDER'),
  ...generateSpecificCrudActionType('GET_LOCATIONS_QUEUE'),
  ...generateSpecificCrudActionType('GET_LOCATIONS_DETAILS'),
  ...generateSpecificCrudActionType('GET_CONFIG_RECORD_TYPES'),
  ...generateSpecificCrudActionType('GET_CASE_DETAIL'),
  ...generateSpecificCrudActionType('PUT_ORDER_STATUS'),
}

export default ConfigTypes
