import { generateCrudActionTypes } from 'shared/helper/redux'

const PermissionActionTypes = {
  SET_LOCATIONS_LIST: 'SET_PERMISSIONS',
  ...generateCrudActionTypes('PERMISSIONS'),
  ...generateCrudActionTypes('ROLES'),
  ...generateCrudActionTypes('PERMISSIONS_GRANTED'),
}

export default PermissionActionTypes
