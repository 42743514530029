/* eslint-disable prefer-destructuring */
import LocationActionTypes from './location.types'
import produce from 'immer'

const INITIAL_STATE = {
  locationList: null,
  singleLocation: {},
  departments: [],
  locationDepartments: [],
  singleLocationDepartment: {},
  recordTypes: [],
  requestTypes: [],
  requestPreferenceList: [],
}

const formatLocation = (location) => {
  const vendorIds = location.vendor.map((vendor) => vendor.id)
  const linkedLocationsIds = location.linkedLocations.map((location) => location.linkedLocationId)
  const locationRequestPreference = location?.locationRequestPreference ?? []

  return {
    id: location.id,
    name: location.name,
    vendorIds: vendorIds,
    vendor: location.vendor,
    linkedLocations: location.linkedLocations,
    linkedLocationsIds: linkedLocationsIds,
    address: location.address.address,
    address1: location.address.address1,
    zipCode: location.address.zipCode,
    cityId: location.address.cityId,
    stateId: location.address.stateId,
    countryId: location.address.countryId,
    countyId: location.address.countyId,
    districtId: location.address.districtId,
    divisionId: location.address.divisionId,
    firstName: location.person.firstName,
    middleName: location.person.middleName,
    lastName: location.person.lastName,
    departmentId: location.departmentId,
    email: location.person.contactInfo.email,
    mobileNumber: location.person.contactInfo.mobileNumber,
    faxNumber: location.person.contactInfo.faxNumber,
    hasAssuranceStatement: location?.hasAssuranceStatement ?? false,
    requestPreferenceIds: locationRequestPreference.map((request) => request.requestPreference.id),
  }
}

const locationReducer = (state = INITIAL_STATE, action) => {
  return produce(state, (draftState) => {
    switch (action.type) {
      case LocationActionTypes.SET_LOCATIONS_LIST:
        draftState.locationList = action.payload
        break

      case LocationActionTypes.GET_REQUEST_PREFERENCE_SUCCESS:
        draftState.requestPreferenceList = action.payload
        break

      case LocationActionTypes.GET_LOCATIONS_SUCCESS:
        draftState.locationList =
          Array.isArray(action.payload) &&
          action.payload.map((location) => {
            return {
              locationId: location.id,
              ...location,
              ...location.address,
              ...location.person,
              ...location.person.contactInfo,
              contactName: `${location.person.firstName} ${location.person.lastName}`,
            }
          })
        break
      case LocationActionTypes.GET_SINGLE_LOCATION_SUCCESS:
        draftState.singleLocation = formatLocation(action.payload)
        break

      case LocationActionTypes.CLEAR_SINGLE_LOCATION:
        draftState.singleLocation = INITIAL_STATE.singleLocation
        break

      case LocationActionTypes.SET_LOCATION_DEPARTMENTS:
        draftState.locationDepartments = action.payload
        break

      case LocationActionTypes.GET_DEPARTMENTS_SUCCESS:
        draftState.departments = action.payload
        break

      case LocationActionTypes.GET_LOCATION_DEPARTMENTS_SUCCESS:
        draftState.locationDepartments = action.payload
        break

      case LocationActionTypes.GET_SINGLE_LOCATION_DEPARTMENT_SUCCESS:
        draftState.singleLocationDepartment = action.payload
        break

      case LocationActionTypes.CLEAR_SINGLE_LOCATION_DEPARTMENT:
        draftState.singleLocationDepartment = INITIAL_STATE.singleLocationDepartment
        break

      case LocationActionTypes.CLEAR_DEPARTMENTS:
        draftState.departments = INITIAL_STATE.departments
        break

      case LocationActionTypes.GET_RECORD_TYPES_SUCCESS:
        draftState.recordTypes = action.payload
        break

      case LocationActionTypes.GET_REQUEST_TYPES_SUCCESS:
        draftState.requestTypes = action.payload
        break

      default:
        break
    }
  })
}

export default locationReducer
