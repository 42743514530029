/* eslint-disable prefer-destructuring */
import produce from 'immer'
import CustomerActionTypes from './customer.types'

const INITIAL_STATE = {
  data: [],
}

const customerReducer = (state = INITIAL_STATE, action) => {
  return produce(state, (draftState) => {
    switch (action.type) {
      case CustomerActionTypes.GET_CUSTOMER_SUCCESS:
        draftState.data = action.payload
        break
      case CustomerActionTypes.SET_CUSTOMER_DATA:
        draftState.data = action.payload
        break

      default:
        return state
    }
  })
}

export default customerReducer
