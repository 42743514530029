/* eslint-disable prefer-destructuring */
import DashboardActionTypes from './dashboard.types'
import produce from 'immer'

const INITIAL_STATE = {
  dashboardQueue: {
    data: [],
    type: null,
  },
}

const dashboardReducer = (state = INITIAL_STATE, action) => {
  return produce(state, (draftState) => {
    switch (action.type) {
      case DashboardActionTypes.GET_DASHBOARD_QUEUE_SUCCESS: {
        draftState.dashboardQueue.data = action.payload
        break
      }
      case DashboardActionTypes.UPDATE_DASHBOARD_TYPE: {
        draftState.dashboardQueue.type = action.payload
        break
      }
      default:
        return state
    }
  })
}

export default dashboardReducer
