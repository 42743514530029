/* eslint-disable prefer-destructuring */
import LegalPersonRolePlayTypes from './legalPersonRolePlay.types'
import produce from 'immer'

const INITIAL_STATE = {
  data: [],
}

const legalPersonRolePlayReducer = (state = INITIAL_STATE, action) => {
  return produce(state, (draftState) => {
    switch (action.type) {
      case LegalPersonRolePlayTypes.GET_LEGAL_PERSON_ROLE_PLAY_SUCCESS: {
        draftState.data = action.payload
        break
      }
      default:
        return state
    }
  })
}

export default legalPersonRolePlayReducer
