/* eslint-disable no-async-promise-executor */
/* eslint-disable react/prop-types */
import React from 'react'
import LayoutComponent from './shared/components/layout/layout.component.jsx'
import './App.css'

const App = () => {
  return <LayoutComponent />
}

export default App

// import React from 'react'
// import ReactDOM from 'react-dom'
// import './App.css'
// import { Page, Text, View, Document, StyleSheet, PDFViewer, Image } from '@react-pdf/renderer'

// const styles = StyleSheet.create({
//   page: {
//     flexDirection: 'row',
//   },
//   section: {
//     margin: 10,
//     padding: 10,
//     flexGrow: 1,
//   },
//   image: {
//     position: 'absolute',
//     minWidth: '100%',
//     minHeight: '100%',
//     display: 'block',
//     height: '100%',
//     width: '100%',
//   },
//   date: {
//     fontSize: 14,
//     marginTop: 112,
//     marginLeft: 35,
//   },
//   client: {
//     fontSize: 10,
//     marginTop: -8,
//     marginLeft: 262,
//   },
//   deposit: {
//     top: 114,
//     marginLeft: 141,
//   },
//   paid: {
//     top: 121,
//     marginLeft: 141,
//   },
//   balance: {
//     fontSize: 10,
//     top: 144,
//     marginLeft: 147,
//   },
//   termination: {
//     top: 164,
//     marginLeft: 140,
//   },
//   signatureCompany: {
//     fontSize: 10,
//     top: 314,
//     marginLeft: 150,
//   },
//   signature: {
//     fontSize: 10,
//     top: 344,
//     marginLeft: 200,
//   },
// })

// const App = () => (
//   <PDFViewer>
//     <Document>
//       <Page size='A4' style={styles.page}>
//         <View
//           style={{
//             position: 'absolute',
//             top: 0,
//             left: 0,
//             right: 0,
//             bottom: 0,
//           }}
//         >
//           <Image src='/Contract.jpg' style={styles.image} />
//           <div style={styles.date}>
//             <Text>Date</Text>
//             <Text style={{ top: -15, marginLeft: 175 }}>order_no</Text>
//           </div>
//           <div style={styles.client}>
//             <Text>Awning and shades by Abe</Text>
//             <Text style={{ top: 8, marginLeft: -225 }}>Toleson 15 Ave</Text>
//             <Text style={{ top: -3, marginLeft: -30 }}>Phoenix</Text>
//             <Text style={{ top: -15, marginLeft: 80 }}>Arizona</Text>
//             <Text style={{ top: -25, marginLeft: 226 }}>55760</Text>
//             <Text style={{ top: 16, marginLeft: -90 }}>date</Text>
//             <Text style={{ top: 40, marginLeft: 120 }}>2 shades</Text>
//             <Text style={{ top: 47, marginLeft: -170 }}>2 shades</Text>
//             <Text style={{ top: 53, marginLeft: -170 }}>2 shades</Text>
//             <Text style={{ top: 60, marginLeft: 225 }}>3,000.00</Text>
//           </div>
//           <div style={styles.deposit}>
//             <Text>x</Text>
//             <Text>x</Text>
//             <Text style={{ top: -12, marginLeft: 24, fontSize: 10 }}>1,500.00</Text>
//           </div>
//           <div style={styles.paid}>
//             <Text>x</Text>
//             <Text>x</Text>
//           </div>
//           <div style={styles.balance}>
//             <Text>1,500.00</Text>
//           </div>
//           <div style={styles.termination}>
//             <Text>x</Text>
//           </div>
//           <div style={styles.signatureCompany}>
//             <Text>Company</Text>
//             <Text style={{ top: -29, marginLeft: 190 }}>date</Text>
//           </div>
//           <div style={styles.signature}>
//             <Text>Signature</Text>
//             <Text style={{ top: -10, marginLeft: 160 }}>date</Text>
//             <Text style={{ top: -4, marginLeft: -50 }}>Shades by Abe</Text>
//             <Text style={{ top: 2, marginLeft: -50 }}>Abraham Orquiz</Text>
//           </div>
//         </View>
//       </Page>
//     </Document>
//   </PDFViewer>
// )

// export default App
