import { generateCrudActionTypes } from 'shared/helper/redux'

const AddressActionTypes = {
  SET_CITIES_LIST: 'SET_CITIES_LIST',
  ...generateCrudActionTypes('STATES'),
  ...generateCrudActionTypes('COUNTIES'),
  ...generateCrudActionTypes('CITIES_BY_ZIP_CODE'),
  ...generateCrudActionTypes('ADDRESS_VALUES'),
}

export default AddressActionTypes
