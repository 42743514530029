export const LOCAL_STORAGE_KEY_PERSISTED_BU = 'persistedBU'
export const LOCAL_STORAGE_KEY_PERSISTED_MENU_COLLAPSE = 'persistedMenuCollapse'

export const DATE_FORMAT = 'MM/DD/YYYY'

export const FILE_LIMITS = {
  FILE_SIZE_LIMIT: 5,
  FILE_COUNT_LIMIT: 10,
  FILE_ALLOWED_TYPES: ['ZIP', 'PDF', 'JPG', 'JPEG', 'PNG', 'GIF', 'DOC', 'DOCX', 'XLS', 'XLSX'],
}

export const MAX_LENGTH_NOTES = 140

export const REDUX_ID_SEPARATOR = '@'

export const ROLE_TYPE_ID_MAP = {
  1: 'external',
  2: 'internal',
  3: 'integration',
}
