/* eslint-disable import/prefer-default-export */
// import { removeAllHttpQueue } from 'redux/api-middleware'
import RouteActionTypes from './route.types'

export const setSelectedRoute = (route) => {
  // clear the active request
  // removeAllHttpQueue()

  return {
    type: RouteActionTypes.SET_SELECTED_ROUTE,
    payload: route,
  }
}
