import { generateCrudActionTypes } from 'shared/helper/redux'

const FirmsActionTypes = {
  UPDATE_TABLE_FIRMS: 'UPDATE_TABLE_FIRMS',
  ...generateCrudActionTypes('FIRMS'),
  ...generateCrudActionTypes('ADDRESS_VALUES_ZIP_CODE'),
  ...generateCrudActionTypes('CITIES_ZIP_CODE'),
  ...generateCrudActionTypes('FIRMS_DETAIL'),
  ...generateCrudActionTypes('FIRMS_TYPE_LIST'),
  ...generateCrudActionTypes('SAVE_FIRMS'),
}

export default FirmsActionTypes
