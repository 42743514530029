import React from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { Menu, Divider } from 'antd'
import { setCurrentBusinessUnit } from 'redux/businesUnit/businessUnit.actions'
import useLocalStorage from 'shared/hooks/useLocalStorage.hook'
import { LOCAL_STORAGE_KEY_PERSISTED_BU } from 'shared/helper/constants'

const SubMenuBusiness = () => {
  const dispatch = useDispatch()
  const businessUnits = useSelector((state) => state.businessUnit.headerBusinessUnits)
  const [, setPersistedBU] = useLocalStorage(LOCAL_STORAGE_KEY_PERSISTED_BU, '')

  const selectBusinessUnit = (businessSchema) => {
    dispatch(setCurrentBusinessUnit(businessSchema))
    setPersistedBU(businessSchema)
  }

  const listBusiness = () => {
    return (
      businessUnits &&
      businessUnits.map((businessUnit) => (
        <Menu.Item
          eventKey={`${businessUnit.businessSchema}`}
          key={`${businessUnit.businessSchema}`}
          onClick={() => selectBusinessUnit(businessUnit.businessSchema)}
        >
          {businessUnit.businessSchema}
        </Menu.Item>
      ))
    )
  }

  return (
    <>
      <Divider key='divider-list-business'>Select a Business ID</Divider>
      <Menu.Divider key='divider-list-items' />
      {listBusiness()}
      <Menu.Divider />
    </>
  )
}

export default SubMenuBusiness
