export const ApiURL = {
  URL_CATALOGS: process.env.REACT_APP_URL_CATALOGS,
}

export const catalogServices = {
  BUSINESS_UNIT_ROUTE: 'business-units',
  ROLE_ROUTE: 'roles',
  USER_ROUTE: 'users',
  TYPE_ROLE_ROUTE: 'role-types',
  GROUP_ROLE_ROUTE: 'role-group',
  PERMISSIONS_ROUTE: 'permissions',
  BUSINESS_UNIT_RELATED_ROUTE: 'business-unit',
  BUSINESS_UNIT_RELATED: 'business-unit',
  REGION_CATALOGS_ROUTE: 'region-catalogs',
  COURT: 'courts',
  VENDORS: 'vendors',
}

export const subCatalogServices = {
  USER_PROFILE: 'user-profile',
  LIST: 'list',
  DEPARTMENTS: 'departments',
  RECORD_TYPES: 'record-types',
  ROLES: 'roles',
  ROLE_PERMISSION: 'role-permission',
  LOCATIONS: 'locations',
  FIRMS: 'firms',
  REQUEST_TYPES: 'request-types',
  REQUEST_PREFERENCE: 'request-preference',
  CITIES: 'cities',
  ZIP_CODE: 'zipcode',
  COUNTRY: 'country',
  STATES: 'states',
  FIRM_TYPES: 'firm-types',
  PERSON_TYPES: 'person-types',
  LEGAL_PERSON: 'legal-person',
  PERSONS: 'persons',
  LIMIT: 'limit',
  FRIM_TYPE: 'firmTypeId',
  LEGAL_PERSON_ROLEPLAY: 'legal-person-roleplay',
  NOTIFICATION: 'notification',
  MAIN: 'main',
  GROUP: 'group',
}
