/* eslint-disable max-len */
/* eslint-disable prefer-destructuring */
import ProductionQueueActionTypes from './productionQueue.types'
import produce from 'immer'
import moment from 'moment'
import { DATE_FORMAT } from 'shared/helper/constants'

const INITIAL_STATE = {
  data: [],
}

const documentsReducer = (state = INITIAL_STATE, action) => {
  return produce(state, (draftState) => {
    switch (action.type) {
      case ProductionQueueActionTypes.GET_PRODUCTION_QUEUE_SUCCESS: {
        const formattedData = []

        action?.payload?.forEach((row) => {
          row?.subject?.subjectLocations?.forEach((subjectLocation) => {
            const subjectFirstName = row?.subject?.person?.firstName
            const subjectLastName = row?.subject?.person?.lastName
            const orderId = row?.subject?.orderId
            const subjectId = row?.subject?.subjectId
            const instanceId = subjectLocation?.instanceId
            const invoiceDate = subjectLocation?.location?.invoiceDate

            const locationId = [orderId, subjectId, instanceId].join('-')

            // OrderId-SubjedId-InstanceId

            const recordTypes = subjectLocation?.scopes?.map((record) => {
              return record?.recordType?.name
            })

            const locationObj = {
              locationId,
              orderId,
              subjectName: [subjectFirstName, subjectLastName].join(' '),
              location: subjectLocation?.location?.name,
              invoiceDate: moment(invoiceDate).format(DATE_FORMAT),
              internalStatus: subjectLocation?.location?.statusName,
              recordTypes: recordTypes.join(', '),
              pendingApproval: true,
            }

            formattedData.push(locationObj)
          })
        })

        draftState.data = formattedData
        break
      }

      default:
        break
    }
  })
}

export default documentsReducer
