/* eslint-disable prefer-destructuring */
import produce from 'immer'
import PaymentMethodActionTypes from './paymentMethod.types'

const INITIAL_STATE = {
  data: null,
}

const paymentMethodReducer = (state = INITIAL_STATE, action) => {
  return produce(state, (draftState) => {
    switch (action.type) {
      case PaymentMethodActionTypes.GET_PAYMENT_METHOD_SUCCESS:
        draftState.data = action.payload
        break

      default:
        return state
    }
  })
}

export default paymentMethodReducer
