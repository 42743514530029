/* eslint-disable prefer-destructuring */
import UserActionTypes from './user.types'
import produce from 'immer'

const INITIAL_STATE = {
  currentUser: {
    id: null,
    name: '',
    username: '',
    secret: null,
  },
  usersList: [],
  users: {
    data: [],
    loading: false,
    error: null,
  },
}

const userReducer = (state = INITIAL_STATE, action) => {
  function findUserList(user) {
    return user.user_id === action.payload.user_id ? { ...action.payload } : { ...user }
  }

  return produce(state, (draftState) => {
    switch (action.type) {
      case UserActionTypes.SET_CURRENT_USER:
        return {
          ...state,
          currentUser: action.payload,
        }
      case UserActionTypes.SET_USERS_LIST:
        return {
          ...state,
          usersList: action.payload,
        }
      case UserActionTypes.ADD_NEW_USER:
        return {
          ...state,
          usersList: [...state.usersList, action.payload],
        }
      case UserActionTypes.UPDATE_USER:
        return {
          ...state,
          usersList: state.usersList.map(findUserList),
        }
      case UserActionTypes.DELETE_USER:
        return {
          ...state,
          usersList: state.usersList.filter((user) => !user.user_id === action.payload.user_id),
        }
      case UserActionTypes.UPDATE_TOKEN_USER:
        return {
          ...state,
          currentUser: {
            ...state.currentUser,
            secret: action.payload,
          },
        }

      case UserActionTypes.GET_USERS_START:
        draftState.users.loading = true
        break

      case UserActionTypes.GET_USERS_SUCCESS:
        draftState.users.data = action.payload
        draftState.users.loading = false
        draftState.users.error = null
        break

      case UserActionTypes.GET_USERS_FAILURE:
        draftState.users.loading = false
        draftState.users.error = action.payload.response
        break

      case UserActionTypes.CLEAR_USERS_INFO:
        draftState.users = INITIAL_STATE.users
        break

      default:
        return state
    }
  })
}

export default userReducer
