/* eslint-disable prefer-destructuring */
import vendorActionTypes from './vendor.types'
import produce from 'immer'

const INITAL_STATE = {
  vendorsList: [],
}

const vendorReducer = (state = INITAL_STATE, action) => {
  return produce(state, (draftState) => {
    switch (action.type) {
      case vendorActionTypes.GET_VENDORS_SUCCESS:
        draftState.vendorsList = action.payload
        break

      default:
        break
    }
  })
}

export default vendorReducer
