import { useState, useEffect } from 'react'
import { useSelector } from 'react-redux'
import get from 'lodash/get'

const useLocalStorage = (key, initialValue) => {
  const userId = useSelector((state) => get(state, ['user', 'currentUser', 'id']))

  const userKey = `${userId}-${key}`

  const getInitialStorageValue = () => {
    try {
      if (!userId) {
        throw new Error()
      }

      // Get from local storage by key
      const item = window.localStorage.getItem(userKey)
      // Parse stored json or if none return initialValue
      return item ? JSON.parse(item) : initialValue
    } catch (error) {
      // If error also return initialValue
      // console.log(error)
      return initialValue
    }
  }
  const [storedValue, setStoredValue] = useState(getInitialStorageValue())

  useEffect(() => {
    userId && setStoredValue(getInitialStorageValue())
  }, [userId])

  const setValue = (value) => {
    try {
      if (!userId) {
        throw new Error()
      }

      const valueToStore = value instanceof Function ? value(storedValue) : value

      setStoredValue(valueToStore)

      window.localStorage.setItem(userKey, JSON.stringify(valueToStore))
    } catch (error) {
      // console.log(error)
    }
  }
  return [storedValue, setValue]
}

export default useLocalStorage
