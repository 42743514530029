import { generateCrudActionTypes } from 'shared/helper/redux'

const OtherCounselTypes = {
  RESET_OTHER_COUNSEL: 'RESET_OTHER_COUNSEL',
  SET_FORM_OTHER_COUNSEL: 'SET_FORM_OTHER_COUNSEL',
  SET_FORM_CASE_INFORMATION: 'SET_FORM_CASE_INFORMATION',
  SET_FORM_SUBJECT_LOCATION: 'SET_FORM_SUBJECT_LOCATION',
  SET_FORM_SCOPE: 'SET_FORM_SCOPE',
  SET_FORM_DOCUMENTS: 'SET_FORM_DOCUMENTS',
  SET_SUMMARY_ORDERING_PARTY: 'SET_SUMMARY_ORDERING_PARTY',
  SET_SUMMARY_BILLING: 'SET_SUMMARY_BILLING',
  SET_SUMMARY_OTHER_COUNSEL: 'SET_SUMMARY_OTHER_COUNSEL',
  SET_SUMMARY_CASE_INFORMATION: 'SET_SUMMARY_CASE_INFORMATION',
  SET_SUMMARY_SUBJECT_LOCATION: 'SET_SUMMARY_SUBJECT_LOCATION',
  SET_SUMMARY_SCOPE: 'SET_SUMMARY_SCOPE',
  SET_SUMMARY_DOCUMENTS: 'SET_SUMMARY_DOCUMENTS',
  ADD_OTHER_COUNSEL: 'ADD_OTHER_COUNSEL',
  EDIT_OTHER_COUNSEL: 'EDIT_OTHER_COUNSEL',
  SET_COUNSEL_MODAL_TYPE: 'SET_COUNSEL_MODAL_TYPE',
  SET_CONTACT_MODAL_TYPE: 'SET_CONTACT_MODAL_TYPE',
  SET_MODAL_COUNSEL_FORM_DATA: 'SET_MODAL_COUNSEL_FORM_DATA',
  RESET_MODAL_COUNSEL: 'RESET_MODAL_COUNSEL',
  ADD_OTHER_COUNSEL_CONTACT: 'ADD_OTHER_COUNSEL_CONTACT',
  EDIT_OTHER_COUNSEL_CONTACT: 'EDIT_OTHER_COUNSEL_CONTACT',
  SET_MODAL_CONTACT_FORM_DATA: 'SET_MODAL_CONTACT_FORM_DATA',
  RESET_MODAL_CONTACTS: 'RESET_MODAL_CONTACTS',
  DELETE_ROW_TABLE_OTHER_COUNSEL: 'DELETE_ROW_TABLE_OTHER_COUNSEL',
  DELETE_ROW_TABLE_OTHER_COUNSEL_CONTACT: 'DELETE_ROW_TABLE_OTHER_COUNSEL_CONTACT',
  ...generateCrudActionTypes('OTHER_COUNSEL'),
}

export default OtherCounselTypes
