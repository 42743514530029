import { generateCrudActionTypes, generateSpecificCrudActionType } from 'shared/helper/redux'

const LocationActionTypes = {
  SET_LOCATIONS_LIST: 'SET_LOCATIONS_LIST',
  CLEAR_SINGLE_LOCATION: 'CLEAR_SINGLE_LOCATION',
  SET_LOCATION_DEPARTMENTS: 'SET_LOCATION_DEPARTMENTS',
  CLEAR_SINGLE_LOCATION_ERRORS: 'CLEAR_SINGLE_LOCATION_ERRORS',
  CLEAR_SINGLE_LOCATION_DEPARTMENT: 'CLEAR_SINGLE_LOCATION_DEPARTMENT',
  CLEAR_DEPARTMENTS: 'CLEAR_DEPARTMENTS',
  ...generateSpecificCrudActionType('GET_REQUEST_PREFERENCE'),
  ...generateCrudActionTypes('LOCATIONS'),
  ...generateSpecificCrudActionType('GET_SINGLE_LOCATION'),
  ...generateCrudActionTypes('DEPARTMENTS'),
  ...generateCrudActionTypes('LOCATION_DEPARTMENTS'),
  ...generateSpecificCrudActionType('GET_SINGLE_LOCATION_DEPARTMENT'),
  ...generateCrudActionTypes('RECORD_TYPES'),
  ...generateCrudActionTypes('REQUEST_TYPES'),
  ...generateCrudActionTypes('ZIP_CODE'),
}

export default LocationActionTypes
