import { generateCrudActionTypes } from 'shared/helper/redux'

const BillingRateActionTypes = {
  SET_BILLING_RATES: 'SET_BILLING_RATES',
  SET_TEMPLATES_BILLING_RATES: 'SET_TEMPLATES_BILLING_RATES',
  SET_ASSIGNED_TEMPLATE: 'SET_ASSIGNED_TEMPLATE',
  ...generateCrudActionTypes('BILLING_RATES'),
}

export default BillingRateActionTypes
