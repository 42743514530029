/* eslint-disable prefer-destructuring */
import RoleActionTypes from './role.types'
import produce from 'immer'

const INITIAL_STATE = {
  data: [],
  // loading: false,
  // error: null,
  singleRole: {},
  roleTypes: [],
  roleGroups: [],
}

const roleReducer = (state = INITIAL_STATE, action) => {
  function findRole(role) {
    return role.role_id === action.payload.role_id ? { ...action.payload } : { ...role }
  }

  return produce(state, (draftState) => {
    switch (action.type) {
      case RoleActionTypes.SET_ROLES_LIST:
        draftState.data = action.payload
        break
      case RoleActionTypes.ADD_NEW_ROLE:
        draftState.data = [...state.rolesList, action.payload]
        break
      case RoleActionTypes.UPDATE_ROLE:
        draftState.data = state.data.map(findRole)
        break
      case RoleActionTypes.DELETE_ROLE:
        draftState.data = draftState.data.filter((role) => role.id !== action.payload)
        break
      case RoleActionTypes.GET_SINGLE_ROLE:
        draftState.singleRole = state.data.find((role) => role.role_id === action.payload)
        break
      case RoleActionTypes.GET_ROLES_SUCCESS:
        draftState.data = action.payload
        break
      case RoleActionTypes.GET_ROLES_FAILURE:
        break
      case RoleActionTypes.GET_ROLE_TYPES_SUCCESS:
        draftState.roleTypes = action.payload
        break
      case RoleActionTypes.GET_ROLE_GROUPS_SUCCESS:
        draftState.roleGroups = action.payload
        break
      case RoleActionTypes.ROLES_RESET:
        return { ...INITIAL_STATE }

      default:
        return state
    }
  })
}

export default roleReducer
