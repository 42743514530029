import { combineReducers } from 'redux'

import documentLibraryReducer from './documentLibrary/documentLibrary.reducer'
import billingRatesReducer from './billingRate/billingRate.reducer'

const rootContactSubEntity = combineReducers({
  billingRates: billingRatesReducer,
  documentLibrary: documentLibraryReducer,
})

export default rootContactSubEntity
