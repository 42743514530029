/* eslint-disable prefer-destructuring */
import produce from 'immer'
import OtherCounselTypes from './otherCounsel.types'

const INITIAL_STATE = {
  counsel: {
    data: [],
    form: {
      selectedCounsel: {
        id: null,
        name: null,
        lastName: null,
        firm: null,
      },
    },
    modalType: null,
  },
  contact: {
    data: [],
    form: {},
    modalType: null,
  },
  response: null,
}

/**
Counsel
id(pin):4
name(pin):"Julio Attorney"
firm(pin):"Firm Test"
representTypeId(pin):4
notes(pin):null
receiveCopy(pin):true
lastName(pin):"Julio"
personId(pin):8
firmId(pin):6
representsName(pin):"Employer"
rowId(pin):1

Contact
id(pin):2
name(pin):"Rachel Zane "
address(pin):"601 E 54th Street"
email(pin):"this.is.real.email@nofake.com"
notes(pin):null
receiveCopy(pin):true
rowId(pin):1
*/

const getOtherCounsel = (oherCounsel) => {
  const contactsAndCounsels = {
    contact: [],
    counsel: [],
  }
  if (oherCounsel.otherCounsels === null) {
    return contactsAndCounsels
  }
  oherCounsel.otherCounsels.forEach((counsel) => {
    if (counsel.isOtherContact) {
      let i = 1
      contactsAndCounsels.contact.push({
        id: counsel.personId,
        name: counsel.person.firstName,
        address: counsel.person.addresses[0].address,
        email: counsel.person.contactInfos[0].email,
        notes: 'none',
        receiveCopy: counsel.receiveCopy,
        rowId: i,
      })
      i += 1
    } else {
      let j = 1
      contactsAndCounsels.counsel.push({
        id: counsel.person.legalPerson[0].id,
        name: counsel.person.firstName,
        email: counsel.person.contactInfos[0].email,
        firm: counsel.person.legalPerson[0].firm.name,
        representTypeId: counsel.representType && counsel.representType.id,
        notes: 'None',
        receiveCopy: counsel.receiveCopy,
        lastName: counsel.person.lastName,
        personId: counsel.personId,
        firmId: counsel.person.legalPerson[0].firm.id,
        representsName: counsel.representType && counsel.representType.name,
        rowId: j,
      })
      j += 1
    }
  })
  return contactsAndCounsels
}

export const otherCounselReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case OtherCounselTypes.SET_COUNSEL_MODAL_TYPE:
      return produce(state, (draftState) => {
        draftState.counsel.modalType = action.payload
      })

    case OtherCounselTypes.SET_MODAL_COUNSEL_FORM_DATA:
      return produce(state, (draftState) => {
        const mergeObject = {
          ...state.counsel.form,
          ...action.payload,
        }
        draftState.counsel.form = mergeObject
      })

    case OtherCounselTypes.ADD_OTHER_COUNSEL:
      return produce(state, (draftState) => {
        const mergeArray = [...state.counsel.data, action.payload]
        draftState.counsel.data = mergeArray
      })

    case OtherCounselTypes.EDIT_OTHER_COUNSEL:
      return produce(state, (draftState) => {
        draftState.counsel.data = action.payload
      })

    case OtherCounselTypes.DELETE_ROW_TABLE_OTHER_COUNSEL:
      return produce(state, (draftState) => {
        draftState.counsel.data = action.payload
      })

    case OtherCounselTypes.RESET_MODAL_COUNSEL:
      return produce(state, (draftState) => {
        draftState.counsel.form = INITIAL_STATE.counsel.form
        draftState.counsel.modalType = null
      })

    case OtherCounselTypes.SET_MODAL_CONTACT_FORM_DATA:
      return produce(state, (draftState) => {
        const mergeObject = {
          ...state.contact.form,
          ...action.payload,
        }
        draftState.contact.form = mergeObject
      })

    case OtherCounselTypes.ADD_OTHER_COUNSEL_CONTACT:
      return produce(state, (draftState) => {
        const mergeArray = [...state.contact.data, action.payload]
        draftState.contact.data = mergeArray
      })

    case OtherCounselTypes.EDIT_OTHER_COUNSEL_CONTACT:
      return produce(state, (draftState) => {
        draftState.contact.data = action.payload
      })

    case OtherCounselTypes.DELETE_ROW_TABLE_OTHER_COUNSEL_CONTACT:
      return produce(state, (draftState) => {
        draftState.contact.data = action.payload
      })

    case OtherCounselTypes.RESET_MODAL_CONTACTS:
      return produce(state, (draftState) => {
        draftState.contact.form = INITIAL_STATE.contact.form
        draftState.contact.modalType = null
      })

    case OtherCounselTypes.SET_CONTACT_MODAL_TYPE:
      return produce(state, (draftState) => {
        draftState.contact.modalType = action.payload
      })
    case OtherCounselTypes.GET_OTHER_COUNSEL_SUCCESS:
      return produce(state, (draftState) => {
        const otherCounsel = getOtherCounsel(action.payload)
        draftState.counsel.data = otherCounsel.counsel
        draftState.contact.data = otherCounsel.contact
        // draftState.response = action.payload
      })

    case OtherCounselTypes.RESET_OTHER_COUNSEL:
      return INITIAL_STATE

    default:
      return state
  }
}

export default otherCounselReducer
