/* eslint-disable prefer-destructuring */
import BillingRateActionTypes from './billingRate.types'
import produce from 'immer'

const INITAL_STATE = {
  templatesBillingRates: [
    {
      id: '1',
      templateName: 'Billing Template 1',
      billingRates: [
        {
          id: '1',
          description: 'Base Fee',
          rate: 100,
          discount: 10,
          type: 'expense',
        },
        {
          id: '2',
          description: 'Court Fee',
          rate: 50,
          discount: 50,
          type: 'fee',
        },
      ],
    },
    {
      id: '2',
      templateName: 'Basic Template',
      billingRates: [],
    },
    {
      id: '3',
      templateName: 'Deluxe Expenses Template',
      billingRates: [
        {
          id: '1',
          description: 'Round Fee',
          rate: 40,
          discount: 20,
          type: 'fee',
        },
        {
          id: '2',
          description: 'Hall Fee',
          rate: 60,
          discount: 50,
          type: 'fee',
        },
        {
          id: '3',
          description: 'Paperwork',
          rate: 1200,
          discount: 30,
          type: 'expense',
        },
      ],
    },
  ],
}

const billingRatesReducer = (state = INITAL_STATE, action) => {
  return produce(state, (draftState) => {
    switch (action.type) {
      // Middleware
      case BillingRateActionTypes.SET_TEMPLATES_BILLING_RATES:
        draftState.templatesBillingRates = action.payload
        break

      case BillingRateActionTypes.SET_ASSIGNED_TEMPLATE: {
        const templateId = action.payload
        draftState.templatesBillingRates = draftState.templatesBillingRates.map((template) => {
          return {
            ...template,
            isAssigned: template.id === templateId,
          }
        })
        break
      }

      case BillingRateActionTypes.GET_BILLING_RATES_SUCCESS: {
        draftState.billingRates = action.payload
        break
      }

      default:
        break
    }
  })
}

export default billingRatesReducer
