import { notification } from 'antd'
import styleVars from '../components/layout/globalVariables.module.scss'
import getColumnSearchProps from 'shared/helper/columnSearch.helper'
// import { DATE_FORMAT } from 'shared/helper/constants'
import moment from 'moment'
/* eslint-disable no-template-curly-in-string */

export const validateMessages = {
  required: '${label} IS REQUIRED!',
  types: {
    email: '${label} IS NOT A VALID EMAIL!',
    number: '${label} IS NOT A VALID NUMBER!',
    password: '${label} IS NOT A VALID PASSWORD!',
  },
  number: {
    len: '${label} MUST EQUAL ${len}!',
    min: '${label} CANNOT BE LESS THAN ${min}!',
    max: '${label} CANNOT BE GREATER THAN ${max}!',
    range: '${label} MUST BE BETWEEN ${min} AND ${max}!',
  },
  pattern: {
    mismatch: '${label} DOES NOT VALID!',
  },
  string: {
    max: '${label} CANNOT BE LONGER THAN ${max} CHARACTERES',
  },
}
/* eslint-enable no-template-curly-in-string */

export const openNotificationWithIcon = (type, message, description) => {
  const backgroundColor =
    type === 'success'
      ? styleVars['color-notification-success']
      : styleVars['color-notification-error']

  const stroke =
    type === 'success'
      ? styleVars['color-notification-success-stroke']
      : styleVars['color-notification-error-stroke']

  const border = `1px solid ${stroke}`

  notification[type]({
    message: message,
    description: description,
    style: { backgroundColor, border },
  })
}

export const removeNullToString = (objCovert) => {
  for (let objIndex in objCovert) {
    if (!objCovert[objIndex]) {
      objCovert[objIndex] = ''
    }
  }
  return objCovert
}
/* unused function
export const messagesResponse = (code, value, title, subtitle) => {
  let message = '';
  switch (code) {
    case 409:
      message = ` A ${title} with ${subtitle} ${value} already exists_ `;
      break;

    default:
      break;
  }
  return message;
}
*/
export const getRoutesBySection = (routes, page, subPage) => {
  let userObj = []
  routes.forEach((route) => {
    if (route.title === page) {
      route.subPages.forEach((subPages) => {
        if (subPages.title === subPage || subPage === null) {
          userObj.push(subPages)
          if (subPages.subPages) {
            subPages.subPages.forEach((subPages) => {
              userObj.push(subPages)
            })
          }
        }
      })
    }
  })

  return userObj
}

export const addActionsItemTable = (title, dataIndex, key, align, render, width) => {
  const actionsColumn = {
    title,
    dataIndex,
    key,
    align: align,
    render: render,
    width,
  }
  return actionsColumn
}

export const layoutForm = (position = 'vertical', span = 24) => {
  const layout = {
    layout: position,
    labelCol: {
      span: span,
    },
    wrapperCol: {
      span: span,
    },
  }
  return layout
}

export const replaceSpaceToUnderscore = (wordReplace) => {
  if (typeof wordReplace !== 'string') {
    throw new Error('Argunment should be String')
  }
  return wordReplace.replaceAll(' ', '_')
}

export const trimAndCapitalizeText = (text) => {
  if (typeof text !== 'string' || text === '') {
    return text
  }

  // eslint-disable-next-line prettier/prettier
  const words = text.trim().toLocaleLowerCase()
    .split(' ')

  return words.map((word) => word[0].toUpperCase() + word.substring(1)).join(' ')
}

export const addQueryToUrl = (url, params) => {
  let copyUrl = url
  if (typeof params !== 'object' || params === null) {
    return url
  }

  const queryString = []

  for (const [key, value] of Object.entries(params)) {
    if (Array.isArray(value)) {
      value.forEach((qParam) => {
        queryString.push(`${key}=${qParam}`)
      })
    } else {
      queryString.push(`${key}=${value}`)
    }
  }

  if (queryString.length) {
    copyUrl = `${url}?${queryString.join('&')}`
  }
  return copyUrl
}

export const getObjNestedValue = (nestedObj, pathArr) => {
  if (typeof nestedObj !== 'object' || nestedObj === null) {
    return undefined
  }

  if (typeof pathArr === 'string' || pathArr instanceof String) {
    return nestedObj[pathArr]
  }

  return pathArr.reduce(
    (obj, key) => (obj && obj[key] !== 'undefined' ? obj[key] : undefined),
    nestedObj,
  )
}

export const columnSortSafe = (value) => (a, b) => {
  const aValue = getObjNestedValue(a, value)
  const bValue = getObjNestedValue(b, value)

  if (!aValue) {
    return -1
  }
  if (!bValue) {
    return 1
  }

  if (typeof aValue === 'string' && typeof bValue === 'string') {
    return aValue.localeCompare(bValue)
  }

  if (typeof aValue === 'number' && typeof bValue === 'number') {
    return aValue > bValue ? 1 : -1
  }

  return 1
  // return aValue.length - bValue.length
}

export const getFullName = (firstName, middleName, lastName) => {
  return `${firstName ? firstName : ''} ${middleName ? middleName : ''} ${lastName ? lastName : ''}`
}

export const extractPermissions = (obj) => {
  const permissionsUser = []

  if (obj && obj.role && obj.role.permissions) {
    obj.role.permissions.forEach((permission) => {
      if (permission.module) {
        const permissionConstruction = `${permission.module}_${replaceSpaceToUnderscore(
          permission.submodule,
        )}_${permission.key}`.toLowerCase()
        permissionsUser.push(permissionConstruction)
      }
    })
  }

  return permissionsUser
}

export const cleanPhoneNumber = (phone) => {
  let phoneCopy = phone
  if (phone !== '') {
    phoneCopy = typeof phone === 'string' ? phone.replace(/\D/g, '') : phone
  } else {
    phoneCopy = null
  }
  return phoneCopy
}

export const rulesPhoneNumber = (phoneType, minDigits = 10) => {
  const regexExp = new RegExp(`(\\D*\\d){${minDigits}}`)

  return [
    // {
    //   min: 10,
    //   message: `${phoneType} NUMBER MUST BE AT LEAST 10 CHARACTERS`,
    // },
    // {
    //   max: 15,
    //   message: `${phoneType} NUMBER CANNOT BE LONGER THAN 15 CHARACTERS`,
    // },
    {
      pattern: regexExp,
      message: `${phoneType} NUMBER MUST HAVE AT LEAST ${minDigits} DIGITS`,
    },
  ]
}

export const GLOBAL_ROLE_TEXT = 'Global Access'

export class CustomError extends Error {
  constructor(message, propertiesObj = {}) {
    super(message)

    for (const [key, value] of Object.entries(propertiesObj)) {
      this[key] = value
    }
  }
}

export const getFileExtFromName = (filename) => {
  return typeof filename === 'string' ? filename.split('.').pop() : ''
}

export const getFilenameWithoutExt = (filename) => {
  return typeof filename === 'string' ? filename.replace(/\.[^/.]+$/, '') : ''
}

export const getZeroFilledNumber = (number, width = 3) => {
  return typeof number === 'number' ? (new Array(width).join('0') + number).substr(-width) : number
}

export const isTokenExpired = (timeExpiration) => {
  const currentTimestamp = new Date().getTime() / 1000
  const isExpired = timeExpiration < currentTimestamp
  return isExpired
}

export const truncateString = (text, length = 40) => {
  if (typeof text !== 'string') {
    return ''
  }
  return text.length > length ? `${text.slice(0, length - 1)}…` : text
}

export const getSelectedRouteByPath = (routes, path) => {
  let [selectedRoute] = routes

  if (path === '/') {
    return selectedRoute
  }

  try {
    for (const route of routes) {
      // console.log('route', route)
      const { title, key, id, subPages, linkUrl } = route
      selectedRoute = {
        title,
        key,
        id,
        linkUrl,
      }

      if (!subPages || !subPages.length) {
        const isSelectedRoute = path.startsWith(linkUrl)

        if (isSelectedRoute) {
          return selectedRoute
        }

        continue
      }

      for (const subPage of subPages) {
        const {
          title: subPageTitle,
          key: subPageKey,
          id: subPageId,
          linkUrl: subPageLinkUrl,
        } = subPage

        const isSelectedRoute = path.startsWith(subPageLinkUrl)
        // console.log('subPage URL', subPageLinkUrl, 'path', path, isSelectedRoute)

        if (isSelectedRoute) {
          selectedRoute.subPage = {
            title: subPageTitle,
            key: subPageKey,
            id: subPageId,
            linkUrl: subPageLinkUrl,
          }
        }
        // END FOR SUBPAGE
      }

      if (selectedRoute.subPage) {
        break
      }
    }
    // END FOR ROUTE
  } catch (err) {
    //
  }

  return selectedRoute
}

export const getPersonTypeQuery = (personsType, attorneyId) => {
  if (!personsType) {
    return null
  }
  let personTypes = []
  personsType.forEach((person) => {
    if (attorneyId) {
      if (attorneyId.name !== person.name) {
        personTypes.push(person.id)
      }
    } else {
      personTypes.push(person.id)
    }
  })
  return personTypes
}

export function formatForTimeAgo(date) {
  const stringFormat = 'MMMM DD, hh:mm a'

  return moment(date).calendar(null, {
    lastDay: '[Yesterday] hh:mm a',
    sameDay: '[Today] hh:mm a',
    nextDay: '[Tomorrow] hh:mm a',
    lastWeek: stringFormat,
    nextWeek: stringFormat,
    sameElse: stringFormat,
  })
}

export const showPaginationEntriesText = (total, range) => {
  // eslint-disable-next-line prefer-destructuring
  const lowerEntry = range[0]
  const upperEntry = range[1] === range[0] ? '' : `-${range[1]}`

  return `Showing ${lowerEntry}${upperEntry} of ${total} ${total === 1 ? 'entry' : 'entries'}.`
}

/* istanbul ignore next */
export const addSearchAndSortToColumns = (
  columns = [],
  { excludeKeys = [], excludeSearch = false, excludeSort = false } = {},
) => {
  let newColumns = []
  try {
    for (const column of columns) {
      const { dataIndex } = column

      if (excludeKeys.includes(dataIndex)) {
        newColumns.push({ ...column })
        continue
      }

      const sortParams = {
        sorter: columnSortSafe(dataIndex),
        sortDirections: ['ascend', 'descend'],
      }

      const searchParams = getColumnSearchProps(dataIndex, { isGlobalRole: column.hasGlobalRole })

      const newColumnObj = {
        ...(!excludeSearch && searchParams),
        ...(!excludeSort && sortParams),
        ...column,
      }

      newColumns.push(newColumnObj)
    }
  } catch (err) {
    // console.log('err', err)
    return columns
  }

  return newColumns
}

export const numberFormatter = (type) => {
  const currencyRegex = /\B(?=(\d{3})+(?!\d))/g

  return (value) => {
    const valueCheck = value || value === 0
    if (type === '$') {
      const parts = value.toString().split('.')
      parts[0] = valueCheck ? `$ ${parts[0]}`.replace(currencyRegex, ',') : ''

      return parts.join('.')
    }

    return valueCheck ? `${value} %` : ''
  }
}

export const roundNumber = (number, decimals) => {
  try {
    const rounded = Math.round(Number(number) * Math.pow(10, decimals)) / Math.pow(10, decimals)
    if (isNaN(rounded)) {
      throw Error()
    }
    return rounded
  } catch (e) {
    return number
  }
}

export const inputNumberCurrencyProps = ({ min } = { min: 0 }) => {
  return {
    min,
    placeholder: '$ 00.00',
    formatter: numberFormatter('$'),
    parser: (value) => {
      const parsed = value.replace(/\$\s?|(,*)/g, '')
      return roundNumber(parsed, 2)
    },
  }
}

export const inputNumberPercentProps = () => {
  return {
    min: 0,
    max: 100,
    placeholder: '0 %',
    formatter: numberFormatter('%'),
    parser: (value) => {
      const parsed = value.replace(' %', '')
      return roundNumber(parsed, 2)
    },
  }
}

export const tableNumberFormatter = (type, decimals = 2) => {
  return (value) => {
    const rounded = `${roundNumber(value, decimals).toFixed(decimals)}`

    let formatted = rounded

    if (type === '$') {
      formatted = numberFormatter('$')(rounded)
    } else if (type === '%') {
      formatted = numberFormatter('%')(rounded)
    }

    return formatted
  }
}

export const multiSelectProps = () => {
  return {
    filterOption: (input, option) => {
      return option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0
    },
  }
}

export const checkAllSelected = (data, key, reference = data.length) => {
  const selected = data.filter((element) => !!element?.[key])

  if (!selected.length) {
    return false
  }

  return selected.length === reference
}

export function findSimilarPropertiesObject(object = {}, property = '') {
  return Object.keys(object).filter((e) => {
    return e.includes(property)
  })
}

export const calculateExtended = (rate, discount, decimals = 2) => {
  if (typeof rate !== 'number' || typeof discount !== 'number') {
    return 0
  }

  try {
    const totalDiscount = rate * (discount / 100)
    return roundNumber(rate - totalDiscount, decimals)
  } catch (e) {
    return 0
  }
}

export function camelize(str) {
  if (typeof str !== 'string') {
    return ''
  }

  return str
    .toLowerCase()
    .replace(/(?:^\w|[A-Z]|\b\w)/g, (word, index) => {
      return index === 0 ? word.toLowerCase() : word.toUpperCase()
    })
    .replace(/\s+/g, '')
}
// FILE HELPERS

/* istanbul ignore next */
export const fileBlobDownload = async (response) => {
  const blob = await response.blob()
  const newBlob = new Blob([blob])

  const blobUrl = window.URL.createObjectURL(newBlob)

  const link = document.createElement('a')
  link.href = blobUrl

  let filename = response.headers.get('X-File-Name') || 'positivePayReport.csv'

  link.setAttribute('download', filename)
  document.body.appendChild(link)
  link.click()
  link.parentNode.removeChild(link)

  window.URL.revokeObjectURL(blob)
}

export const currencyFormat = (number) => {
  return new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(number)
}

export const formatDate = (date) => {
  return moment(date).format('MM/DD/YYYY')
}
