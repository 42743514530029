/* eslint-disable prefer-destructuring */
import permissionActionTypes from 'redux/permission/permission.types'
import produce from 'immer'

const INITAL_STATE = {
  permissionsList: {
    data: [],
  },
  permissionsGranted: {
    data: [],
  },
  rolesList: {
    data: [],
  },
}

const permissionReducer = (state = INITAL_STATE, action) => {
  return produce(state, (draftState) => {
    switch (action.type) {
      case permissionActionTypes.SET_PERMISSIONS:
        draftState.permissionsList.data = action.payload
        break
      // region PERMISSIONS
      case permissionActionTypes.GET_PERMISSIONS_SUCCESS:
        draftState.permissionsList.data = action.payload
        break
      case permissionActionTypes.GET_PERMISSIONS_FAILURE:
        break
      // endregion
      // region PERMISSIONS GRANTED
      case permissionActionTypes.GET_PERMISSIONS_GRANTED_SUCCESS:
        draftState.permissionsGranted.data = action.payload
        break
      case permissionActionTypes.GET_PERMISSIONS_GRANTED_FAILURE:
        break
      case permissionActionTypes.PUT_PERMISSIONS_GRANTED_SUCCESS:
        break
      case permissionActionTypes.PUT_PERMISSIONS_GRANTED_FAILURE:
        break
      // endregion
      default:
        break
    }
  })
}

export default permissionReducer
