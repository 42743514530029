import { generateCrudActionTypes } from 'shared/helper/redux'

const SubjectLocationsTypes = {
  RESET_SUBJECT_LOCATION: 'RESET_SUBJECT_LOCATION',
  SET_FORM_SUBJECT_LOCATION: 'SET_FORM_SUBJECT_LOCATION',
  SAVE_MODAL_DATA_SUBJECT_LOCATION: 'SAVE_MODAL_DATA_SUBJECT_LOCATION',
  UPDATE_MODAL_DATA_SUBJECT_LOCATION: 'UPDATE_MODAL_DATA_SUBJECT_LOCATION',
  UPDATE_MORE_LOCATIONS_SELECTED: 'UPDATE_MORE_LOCATIONS_SELECTED',
  UPDATE_RECOMMENDED_LOCATIONS_SELECTED: 'UPDATE_RECOMMENDED_LOCATIONS_SELECTED',
  UPDATE_DATA_TABLE_MORE_LOCATIONS: 'UPDATE_DATA_TABLE_MORE_LOCATIONS',
  UPDATE_DATA_TABLE_RECOMMENDED_LOCATIONS: 'UPDATE_DATA_TABLE_RECOMMENDED_LOCATIONS',
  DELETE_SUBJECT_LOCATION: 'DELETE_SUBJECT_LOCATION',
  DELETE_LOCATION: 'DELETE_LOCATION',
  UPDATE_TABLE_LOCATION: 'UPDATE_TABLE_LOCATION',
  CLEAR_DATA_TABLE_SUBJECT_LOCATIONS: 'CLEAR_DATA_TABLE_SUBJECT_LOCATIONS',
  ...generateCrudActionTypes('SUBJECT_LOCATION'),
}

export default SubjectLocationsTypes
