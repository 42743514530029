import configReducer from './config/config.reducer'
import orderingPartyReducer from './orderingParty/orderingParty.reducer'
import billingReducer from './billing/billing.reducer'
import otherCounselReducer from './otherCounsel/otherCounsel.reducer'
import caseInformationReducer from './caseInformation/caseInformation.reducer'
import scopeReducer from './scope/scope.reducer'
import subjectLocationReducer from './subjectLocations/subjectLocations.reducer'
import documentsReducer from './documents/documents.reducer'
import summaryReducer from './summary/summary.reducer'
import { combineReducers } from 'redux'

const rootOrderIntakeReducer = combineReducers({
  config: configReducer,
  orderingParty: orderingPartyReducer,
  billing: billingReducer,
  otherCounsel: otherCounselReducer,
  caseInformation: caseInformationReducer,
  subjectLocation: subjectLocationReducer,
  scope: scopeReducer,
  documents: documentsReducer,
  summary: summaryReducer,
})

export default rootOrderIntakeReducer
