import { generateSpecificCrudActionType } from 'shared/helper/redux'

const DashboardActionTypes = {
  UPDATE_DASHBOARD_TYPE: 'UPDATE_DASHBOARD_TYPE',
  //
  ...generateSpecificCrudActionType('GET_DASHBOARD_QUEUE'),
  ...generateSpecificCrudActionType('GET_TEST'),
}

export default DashboardActionTypes
