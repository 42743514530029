/* eslint-disable prefer-destructuring */
import PersonTypeActionTypes from './personType.types'
import produce from 'immer'

const INITIAL_STATE = {
  loading: false,
  error: null,
  data: null,
  attorneyId: null,
}

const personTypeReducer = (state = INITIAL_STATE, action) => {
  return produce(state, (draftState) => {
    switch (action.type) {
      case PersonTypeActionTypes.GET_PERSON_TYPE_START:
        draftState.loading = true
        break
      case PersonTypeActionTypes.GET_PERSON_TYPE_SUCCESS:
        draftState.loading = false
        draftState.data = action.payload
        break
      case PersonTypeActionTypes.GET_PERSON_TYPE_FAILURE:
        draftState.loading = false
        draftState.error = action.payload
        break
      case PersonTypeActionTypes.GET_PERSON_TYPE_ATTORNEY_ID:
        draftState.attorneyId = state.data.filter((personType) => personType.name === 'Attorney')[0]
        break
      default:
        return state
    }
  })
}

export default personTypeReducer
