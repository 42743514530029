/* eslint-disable prefer-destructuring */
import ConfigTypes from './config.types'
import produce from 'immer'
import { getFullName } from 'shared/helper/function.helper'
import { ROLE_TYPE_ID_MAP } from 'shared/helper/constants'

const INITIAL_STATE = {
  id: null,
  activeStep: 0,
  isUpdateMode: false,
  isNextAvailable: false,
  data: null,
  loading: false,
  error: null,
  selectedView: {
    order: {
      active: true,
    },
    location: {
      active: false,
    },
  },
  orderDetail: {
    type: '',
    data: null,
  },
  locationDetail: {
    data: null,
  },
  recordTypes: [],
}

const cleanObjectOrderDetailsExternal = (orderDetail) => {
  return {
    caseNumber: orderDetail.orderId,
    firmName: orderDetail?.firms[0]?.name,
    requestName: getFullName(
      orderDetail?.orderingAttorney[0]?.person?.firstName,
      orderDetail?.orderingAttorney[0]?.person?.lastName,
    ),
    caseStyle: orderDetail?.caseInfo?.filedCaseCaptions?.caption?.captionName,
    court: orderDetail?.caseInfo?.court?.name,
    county: orderDetail?.caseInfo?.court?.courtAddresses[0]?.address?.county?.name,
    state: orderDetail?.caseInfo?.court?.courtAddresses[0]?.address?.state?.name,
  }
}

const cleanObjectOrderDetailsInternal = (orderDetail) => {
  const { subjectLocations } = orderDetail
  const subjects = []
  subjectLocations.forEach((subject) => {
    subjects.push({
      locationNumber: subject?.locationNumber,
      subject: getFullName(subject?.person?.firstName, subject?.person?.lastName),
      locationName: subject?.location?.name,
      recordsRequest: subject?.requestType,
      recordType: subject?.recordType,
      turnaround: 'NA',
      subjectId: subject.subjectId,
      instanceId: subject.instanceId,
      locationId: subject.locationId,
      requestTypeId: subject.requestTypeId,
    })
  })
  return {
    caseNumber: orderDetail.orderId,
    firmName: orderDetail?.orderingAttorney?.firm?.name,
    attorneyName: getFullName(
      orderDetail?.orderingAttorney?.person?.firstName,
      orderDetail?.orderingAttorney?.person?.lastName,
    ),
    billingFirmName: orderDetail?.billingAttorney?.firm?.name,
    billingName: getFullName(
      orderDetail?.billingAttorney?.person?.firstName,
      orderDetail?.billingAttorney?.person?.lastName,
    ),
    subjectLocations: subjects,
    billing: {
      firm: orderDetail?.billingAttorney?.firm?.name,
      attorney: getFullName(
        orderDetail?.billingAttorney?.person?.firstName,
        orderDetail?.billingAttorney?.person?.lastName,
      ),
      claimNumber: orderDetail?.claimNumber,
    },
    caseInfo: {
      causeNumber: orderDetail?.caseInfo?.causeNumber,
      clientMatterNumber: orderDetail?.caseInfo?.clientMatterNumber,
      trialDate: 'NA',
      state: orderDetail?.caseInfo?.court?.courtAddresses[0]?.address?.state?.name ?? 'NA',
      county: orderDetail?.caseInfo?.court?.courtAddresses[0]?.address?.county?.name ?? 'NA',
      district: orderDetail?.caseInfo?.court?.courtAddresses[0]?.address?.district?.name ?? 'NA',
      division: orderDetail?.caseInfo?.court?.courtAddresses[0]?.address?.division?.name ?? 'NA',
      // court: orderDetail?.caseInfo?.court?.name ?? 'NA',
      filedCase: orderDetail?.caseInfo,
    },
    otherCounsels: orderDetail?.ordersOtherCounsel || [],
  }
}

const configReducer = (state = INITIAL_STATE, action) => {
  return produce(state, (draftState) => {
    switch (action.type) {
      case ConfigTypes.SET_ACTIVE_STEP:
        draftState.activeStep = action.payload
        break

      case ConfigTypes.SET_UPDATE_MODE:
        draftState.isUpdateMode = action.payload
        break

      case ConfigTypes.SET_NEXT_AVAILABLE:
        draftState.isNextAvailable = action.payload
        break

      case ConfigTypes.SET_SELECTED_VIEW: {
        for (const activeValue in draftState.selectedView) {
          draftState.selectedView[activeValue].active = false
          if (action.payload === activeValue) {
            draftState.selectedView[activeValue].active = true
          }
        }
        break
      }

      case ConfigTypes.GET_ORDER_START:
        draftState.loading = true
        break

      case ConfigTypes.GET_ORDER_SUCCESS:
        draftState.data = action.payload
        draftState.loading = false
        break

      case ConfigTypes.GET_ORDER_FAILURE:
        draftState.loading = false
        draftState.error = action.payload
        break
      case ConfigTypes.SET_ID_ORDER:
        draftState.id = action.payload
        break

      case ConfigTypes.GET_EXTERNAL_CUSTOMER_ORDER_DETAILS_SUCCESS:
        draftState.orderDetail.data = cleanObjectOrderDetailsExternal(action.payload)
        draftState.orderDetail.type = ROLE_TYPE_ID_MAP[1]
        break

      case ConfigTypes.GET_CASE_DETAIL_SUCCESS:
        draftState.orderDetail.data = cleanObjectOrderDetailsInternal(action.payload)
        draftState.orderDetail.type = ROLE_TYPE_ID_MAP[2]
        break

      case ConfigTypes.GET_LOCATIONS_QUEUE_SUCCESS:
        draftState.data = action.payload
        break

      case ConfigTypes.GET_LOCATIONS_DETAILS_SUCCESS:
        draftState.locationDetail.data = action.payload
        break

      case ConfigTypes.GET_CONFIG_RECORD_TYPES_SUCCESS:
        draftState.recordTypes = action.payload
        break

      case ConfigTypes.RESET_CONFIG:
        return { ...INITIAL_STATE, selectedView: draftState.selectedView }

      default:
        break
    }
  })
}

export default configReducer
