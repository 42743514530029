/* eslint-disable prefer-destructuring */
import ContactActionTypes from './contact.types'
import produce from 'immer'

const INITIAL_STATE = {
  contactList: [],
  counsels: {
    data: null,
    loading: false,
    error: null,
  },
  contacts: {
    data: null,
    loading: false,
    error: null,
  },
  contact: null,
}

const getCounselData = (counsels) => {
  return counsels.map((counsel) => {
    const legalPerson = counsel.legalPerson[0]
    const firm = legalPerson.firm
    return {
      id: legalPerson.id,
      personId: counsel.id,
      name: counsel.firstName,
      lastName: counsel.lastName,
      firm: firm.name,
      firmId: firm.id,
    }
  })
}
const getContactsData = (contacts) => {
  return contacts.map((contact) => {
    const conctactInfos = contact.contactInfos[0]

    const { addresses, firms } = contact

    let addressesData = {}

    if (addresses.length > 0) {
      addressesData = addresses[0]
    } else if (firms) {
      addressesData = firms[0].addresses[0]
    }

    return {
      id: contact.id,
      name: contact.firstName,
      lastName: contact.lastName,
      address: addressesData && addressesData.address,
      email: conctactInfos.email,
    }
  })
}

const contactReducer = (state = INITIAL_STATE, action) => {
  return produce(state, (draftState) => {
    switch (action.type) {
      case ContactActionTypes.SET_CONTACT_LIST:
        draftState.contactList = action.payload
        break
      case ContactActionTypes.GET_COUNSEL_LIST_START:
        draftState.counsels = {
          ...draftState.counsels,
          loading: true,
        }
        break
      case ContactActionTypes.GET_COUNSEL_LIST_SUCCESS:
        draftState.counsels = {
          ...draftState.counsels,
          loading: false,
          data: getCounselData(action.payload),
        }
        break
      case ContactActionTypes.GET_COUNSEL_LIST_FAILURE:
        draftState.counsels = {
          ...draftState.counsels,
          loading: false,
          error: action.payload,
        }
        break
      case ContactActionTypes.GET_CONTACTS_LIST_START:
        draftState.contacts = {
          ...draftState.contacts,
          loading: true,
        }
        break
      case ContactActionTypes.GET_CONTACTS_LIST_SUCCESS:
        draftState.contacts = {
          ...draftState.contacts,
          loading: false,
          data: getContactsData(action.payload),
        }
        break
      case ContactActionTypes.GET_CONTACTS_LIST_FAILURE:
        draftState.contacts = {
          ...draftState.contacts,
          loading: false,
          error: action.payload,
        }
        break
      case ContactActionTypes.GET_CONTACT_SUCCESS:
        draftState.contact = action.payload
        break
      default:
        return state
    }
  })
}

export default contactReducer
