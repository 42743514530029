/* eslint-disable prefer-destructuring */
import DocumentsActionTypes from './documents.types'
import produce from 'immer'
import { REDUX_ID_SEPARATOR } from 'shared/helper/constants'
import { getReduxIdSuffix } from 'shared/helper/redux'

const INITIAL_STATE = {
  data: [],
  batchLoading: false,
  formErrors: {},
  unsavedFiles: {},
}

const documentsReducer = (state = INITIAL_STATE, action) => {
  return produce(state, (draftState) => {
    const [, id] = action.type.split(REDUX_ID_SEPARATOR)

    switch (action.type) {
      case DocumentsActionTypes.SET_TABLE_DOCUMENTS:
        draftState.data = action.payload
        break

      case DocumentsActionTypes.SET_FORM_ERRORS:
        draftState.formErrors = { ...draftState.formErrors, ...action.payload }
        break

      case DocumentsActionTypes.RESET_FORM_ERRORS:
        draftState.formErrors = {}
        break

      case DocumentsActionTypes.SET_UNSAVED_FILES:
        draftState.unsavedFiles = { ...draftState.unsavedFiles, ...action.payload }
        break

      case DocumentsActionTypes.RESET_UNSAVED_FILES:
        draftState.unsavedFiles = {}
        break

      case DocumentsActionTypes.SET_BATCH_LOADING:
        draftState.batchLoading = action.payload
        break

      case DocumentsActionTypes.GET_ORDER_DOCUMENTS_SUCCESS: {
        const filesData =
          action?.payload?.documents?.map((file) => {
            return {
              fileUID: file?.document?.documentId,
              fileName: file?.document?.name,
              fileOriginalName: file?.document?.name,
              fileType: file?.document?.extension?.toUpperCase(),
              mimeType: file?.document?.mimeType,
            }
          }) ?? []
        draftState.data = filesData
        break
      }

      case DocumentsActionTypes.DELETE_ROW_TABLE_DOCUMENTS: {
        const newFileList = state.data.filter((file) => file.fileUID !== action.fileUID)
        draftState.data = newFileList
        break
      }

      case DocumentsActionTypes.RESET_DOCUMENTS:
        return INITIAL_STATE

      case `${DocumentsActionTypes.POST_ORDER_DOCUMENTS_SUCCESS}${getReduxIdSuffix(id)}`: {
        //
        break
      }

      default:
        break
    }
  })
}

export default documentsReducer
