/* eslint-disable prefer-destructuring */
import SummaryTypes from './summary.types'
import produce from 'immer'

const INITIAL_STATE = {
  orderingParty: null,
  billing: null,
  // {
  //   billingFirm: null,
  //   firmAddress: null,
  //   claimNumber: null,
  //   attorney: null,
  // },
  otherCounsel: [],
  caseInformation: {},
  subjectLocations: [],
  scope: {},
  documents: [],
  additionalNotes: '',
}

export const summaryReducer = (state = INITIAL_STATE, action) => {
  return produce(state, (draftState) => {
    switch (action.type) {
      case SummaryTypes.SET_SUMMARY_ORDERING_PARTY:
        draftState.orderingParty = action.payload
        break

      case SummaryTypes.SET_SUMMARY_BILLING:
        draftState.billing = action.payload
        break

      case SummaryTypes.SET_SUMMARY_OTHER_COUNSEL:
        draftState.otherCounsel = action.payload
        break

      case SummaryTypes.SET_SUMMARY_CASE_INFORMATION:
        draftState.caseInformation = action.payload
        break
      case SummaryTypes.SET_SUMMARY_ADDITIONAL_NOTES:
        draftState.additionalNotes = action.payload
        break

      case SummaryTypes.SET_SUMMARY_SUBJECT_LOCATION:
        draftState.subjectLocations = action.payload
        break

      case SummaryTypes.SET_SUMMARY_DOCUMENTS:
        draftState.documents = action.payload
        break

      case SummaryTypes.SET_SUMMARY_SCOPE:
        draftState.scope = action.payload
        break

      case SummaryTypes.RESET_SUMMARY:
        return INITIAL_STATE

      default:
        break
    }
  })
}

export default summaryReducer
