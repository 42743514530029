/* eslint-disable max-len */
import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { Route, Switch, Redirect, Link } from 'react-router-dom'
import { Layout } from 'antd'
import { LOCAL_STORAGE_KEY_PERSISTED_MENU_COLLAPSE } from 'shared/helper/constants'
import Menu from 'shared/components/menu/menu.component'
import HeaderComponent from 'shared/components/header/header.component'
import useLocalStorage from 'shared/hooks/useLocalStorage.hook'

import './customAntd.styles.scss'
import './layout.styles.scss'

const Dashboard = React.lazy(() => import('dashboard/pages/dashboard.component'))
const Estimate = React.lazy(() => import('estimate/pages/estimates.component'))
const Customer = React.lazy(() => import('customer/pages/customers.component'))

const ImageHandler = ({ isCollapsed }) => {
  return isCollapsed ? (
    <Link to='/'>
      <img alt='IconOnly' className='iconOnly' src='/IconOnly.png' />
    </Link>
  ) : (
    <Link to='/'>
      <img alt='Logo' className='logoImg' src='/logo.png' />
    </Link>
  )
}

function LayoutComponent() {
  const [persistedMenuCollapse, setPersistedMenuCollapse] = useLocalStorage(
    LOCAL_STORAGE_KEY_PERSISTED_MENU_COLLAPSE,
    false,
  )
  const [collapsed, setCollapsed] = useState(persistedMenuCollapse)

  const updateToggle = () => {
    setPersistedMenuCollapse(!collapsed)
    setCollapsed(!collapsed)
  }

  return (
    <Layout>
      <Layout.Sider
        className='site-layout-background'
        trigger={null}
        collapsible
        collapsed={collapsed}
      >
        <div className='logo'>
          <ImageHandler isCollapsed={collapsed} />
        </div>
        <Menu updateToggle={updateToggle} collapsed={collapsed} />
      </Layout.Sider>
      <Layout className='site-layout'>
        <HeaderComponent />
        <Layout.Content
          className='site-layout-background'
          style={{
            padding: '24px 16px',
            margin: 0,
            minHeight: 280,
          }}
        >
          <Switch>
            <React.Suspense fallback={null}>
              <Route path='/' exact>
                <Redirect to='/dashboard' />
              </Route>
              <Route path='/dashboard' component={Dashboard} permissions={null} />
              <Route path='/estimate' component={Estimate} permissions={null} />
              <Route path='/customer' component={Customer} permissions={null} />
            </React.Suspense>
          </Switch>
        </Layout.Content>
        {/* <Layout.Footer
          style={{
            textAlign: 'center',
            backgroundColor: '#ffffff',
            borderTop: '1px solid #f1f2f2',
          }}
        /> */}
      </Layout>
    </Layout>
  )
}

ImageHandler.propTypes = {
  isCollapsed: PropTypes.bool.isRequired,
}

export default LayoutComponent
