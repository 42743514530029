import advancedSearchTypes from './advancedSearch.types'

const INITIAL_STATE = {
  advancedSearch: {
    loading: false,
    error: null,
    filters: {
      recordType: null,
      assignedTo: null,
      rss: null,
      rush: null,
      status: null,
      datePicker: null,
      rangePicker: null,
      rangePicker2: null,
      hideAchived: null,
      onlyCallbacks: null,
    },
  },
}

const advanceSearchReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case advancedSearchTypes.SET_ADVANCED_SEARCH:
      return {
        ...state,
        filters: action.payload,
      }
    default:
      return state
  }
}

export default advanceSearchReducer
