/* eslint-disable */
// we disable eslint because process.env cannot do object destructuring
import React from 'react'
import ReactDOM from 'react-dom'
import { BrowserRouter as Router } from 'react-router-dom'
import { Provider } from 'react-redux'
import startConfig from 'config/lexitas'
import { store } from 'redux/store'
import App from 'App'


async function initApp() {
  try {
    if (true) {
      // msalInstance.setActiveAccount(accounts[0])
      ReactDOM.render(
          <Provider store={store}>
            <Router>
              {/* <React.StrictMode> */}
                <App/>
              {/* </React.StrictMode> */}
            </Router>
          </Provider>,
          document.getElementById('root'),
      )
    } else {
      // msalInstance.loginRedirect(loginRequest)
      console.error('Non connected')
    }
  } catch (error) {
    /**
     * TODO: this need to be validate error url by the server,
     * for the moment, it will be redirected to homepage
     * to redirect to MSAL login url
     */
    window.location.href = process.env.REACT_APP_REDIRECT_URI
  }
}

initApp()